<h1>BlueScan Extraction Test Webapp</h1>

<div class="row">
    <div class="col-md-6">
        <app-document-extraction
            [left]="10" 
            [top]="10"
        ></app-document-extraction>
    </div>
    <div class="com-md-6 instructionText">
        <div>
            Premi sul pulsante 
            
            <button class="action-button fake-file-upload">
                <em class="fa fa-plus"></em> Foto
            </button>
            
            per scegliere la modalità di acquisizione del documento.
        </div>
        <div>
            Per confermare i documenti caricati premere il pulsante <button class="action-button fake-terminate-procedure">Termina procedura</button>.
        </div>
    </div>
</div>


<div class="row terminateProcedureButton">
    <div class="col-md-5"></div>
    <div class="col-md-5">
        <button class="action-button terminate-procedure">Termina procedura</button>
    </div>
</div>
