

<div class="resizable-draggable documentExtraction"
[style.transform]="'translate3d(' + left + 'px,' + top + 'px,' + '0px)'" *ngIf="appear || firstCard">


    <!-- Header della card: di base ha sfondo grigio ma può variare con l'icona dell'omino mentre c'è il caricamento, oppure con OK -->
    <div class="card-header documentExtraction">
        <div class="row">
            <mat-form-field appearance="fill" class="searchBar">
                <mat-label><div class="lightBlue">Seleziona un documento</div></mat-label>
                <mat-select [disabled]="isOneBarIndeterminate() || isOneProgressBarEqualTo100()" [(value)]="selectedDocument" (selectionChange)="documentHasChanged($event.value)">
                    <mat-option></mat-option>
                    <mat-option class="blue" [value]="option" *ngFor="let option of documenti">{{ option.externalDescription }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="titleCurrentDocument" *ngIf="selectedDocument"><strong>Caricamento: {{selectedDocument.externalDescription}}</strong></div>            
        </div>
    </div>




    <div class="card-body">

        <!-- NUOVO SVILUPPO -->
        <div *ngIf="selectedDocument && selectedDocument.parts">
            <div *ngFor="let part of partInLexicalOrder(selectedDocument.parts)">
                
                <div *ngIf="part.active === true">

                    <div *ngIf="!urls[part.name]"> <!-- NON Visualizzazione della foto -->
                        
                        <div class="row"> <!-- Riga Immagine -->
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <img alt="Immagine documento" class="document-image imageDocument" [src]="image">
                            </div>
                            <div class="col-md-2"></div>
                
                            <div class="col-md-4 blueIsolatedButton" *ngIf="selectedDocument">

                                <span *ngIf="submissionTypes.includes('IMAGE') && submissionTypes.length === 1">
                                
                                    <label for="file-upload-{{part.partId}}" class="action-button file-upload">
                                        <span class="textInsideButton"><em class="fa fa-plus"></em> {{part.externalDescription}}</span>
                                    </label>
                                    <input id="file-upload-{{part.partId}}" (change)="uploadDocumentImage($event, part)" type="file"/>

                                </span>

                                <span *ngIf="submissionTypes.includes('NATIVE_PDF') && submissionTypes.length === 1">
                                
                                    <label for="file-upload-{{part.partId}}" class="action-button file-upload">
                                        <span mat-raised-button #tooltip="matTooltip"
                                        matTooltip="Attenzione! Seleziona solamente PDF nativi"
                                        matTooltipHideDelay="100"
                                        
                                        class="example-button" class="textInsideButton"><em class="fa fa-plus"></em> PDF</span>
                                    </label>
                                    <input id="file-upload-{{part.partId}}" (change)="uploadDocumentNativePDF($event, part)" type="file"/>

                                </span>

                                <span *ngIf="submissionTypes.includes('IMAGE') && submissionTypes.includes('SCANNED_PDF') && submissionTypes.length === 2">
                                
                                    <label for="file-upload-{{part.partId}}" class="action-button file-upload">
                                        <span class="textInsideButton"><em class="fa fa-plus"></em> IMMAGINE/PDF</span>
                                    </label>
                                    <input id="file-upload-{{part.partId}}" (change)="uploadDocumentImagePdf($event, part)" type="file"/>

                                </span>
                
                            </div>
                        </div>
                        

                    </div>

                    <div *ngIf="urls[part.name]"> <!-- Visualizzazione della foto -->
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-9">
                                        <strong>{{imageFiles[part.name].name}}</strong>
                                    </div>
                                    
                                    <div class="col-md-3">
                                        {{formatBytes(imageFiles[part.name].size)}}
                                    </div>
                                </div>
                                <div class="row">
                                    <mat-progress-bar [mode]="modeProgressBars[part.name]" [value]="valueProgressBars[part.name]"></mat-progress-bar>
                                </div>
                            </div>
                            <div class="col-md-4 uploadOrDelete"> <!-- Colonna dei pulsanti -->
                                <span *ngIf="valueProgressBars[part.name] != 100">
                                    <button (click)="startLoadingPhoto(part)" [disabled]="modeProgressBars[part.name] === 'indeterminate'? true : false" class="action-button-2 file-start-upload" *ngIf="true">
                                        <em class="fa fa-upload"></em> Carica
                                    </button>
                                    <button (click)="removeUploadedPhoto(part.name)" [disabled]="modeProgressBars[part.name] === 'indeterminate'? true : false" class="action-button-2 file-remove-upload" *ngIf="true">
                                        <em class="fa fa-ban"></em> Annulla
                                    </button>
                                </span>

                                <button (click)="deleteUploadedPhoto(part)" [disabled]="modeProgressBars[part.name] === 'indeterminate'? true : false" class="action-button file-delete-upload" *ngIf="valueProgressBars[part.name] === 100">
                                    <em class="fa fa-trash"></em> Cancella
                                </button>
                            </div>

                            
                            <div class="row">



                                <div class="col-md-2"></div>
                                <div class="col-md-4">

                                    <div *ngIf="imageFiles[part.name] && allowedImagesFormats.includes(imageFiles[part.name].type)">
                                        <img alt="Immagine documento" class="imageDocument" [src]="urls[part.name]">
                                    </div>
                                    
                                    <div class="pdf-div" *ngIf="imageFiles[part.name] && allowedIncomeFormats.includes(imageFiles[part.name].type)">
                                
                                        <ng2-pdfjs-viewer [pdfSrc]="imageFiles[part.name]" [page]="1"></ng2-pdfjs-viewer>
                                       
                                    </div>
                                </div>
                                <div class="col-md-2"></div>

                            </div>
                            

                        </div>
                    </div>
                    

                </div>
            </div>
        </div>


        

</div>




    
</div>

