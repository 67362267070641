import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SessionsService, PostSessionRequest } from '../api';

@Component({
  selector: 'app-home-alternative',
  templateUrl: './home-alternative.component.html',
  styleUrls: ['./home-alternative.component.scss']
})
export class HomeAlternativeComponent implements OnInit {

  Object = Object;

  deleteAll = false;

  // Servono per lo spinner
  element;
  loading;

  public showDocumentClassificationFronte = false; // A false fa vedere il testo delle istruzioni. A true mostra il componente a schermo
  public showDocumentClassificationRetro = false; // A false fa vedere il testo delle istruzioni. A true mostra il componente a schermo

  public objectElaborationsFronte; // Oggetto che contiene tutte le informazioni essenziali per il fronte del documento
  public objectElaborationsRetro; // Oggetto che contiene tutte le informazioni essenziali per il retro del documento

  public objectElaborations = {};
  public showDocumentClassification = {};

  public elaborationDone = false; // Indica che è stata fatta l'elaborazione finale

  constructor(
    private sessionsService: SessionsService,
    private cdr: ChangeDetectorRef
  ) {
    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');

    //sessionStorage.clear();
  }

  async ngOnInit() {

    sessionStorage.setItem("correctLogin","true")

    var body;
    body = {};

    let responseSessions = await this.sessionsService.createSession(body);
    responseSessions.subscribe(
      res => {
        console.log("Sessione creata: ", res['sessionId'])
        sessionStorage.setItem('sessionId', res['sessionId']);
      
      }, (error) => {
        console.log("ERRORE: nessuna sessione caricata")
      }
    )


  }

  documentsRemained;

  arrivedData(objectWithData) {
    this.documentsRemained = objectWithData.object.documentsExternal;
    this.objectElaborations[objectWithData.object.docId] = objectWithData.object;
    this.showDocumentClassification[objectWithData.object.docId] = true;

    this.disableAddDocument = false;
  }

  deleteData(objectWithData) {

    if(objectWithData && objectWithData.object && objectWithData.object.docId) {

      if(!this.isEmpty(objectWithData.nullable)) {
        if(this.documentsRemained.indexOf(objectWithData.nullable) === -1) {
          this.documentsRemained.push(objectWithData.nullable)
        }
      }
      

      this.objectElaborations[objectWithData.object.docId] = undefined;
      this.showDocumentClassification[objectWithData.object.docId] = false;
    }

  }

  public allShowDocumentClassificationAreFalse() {

    for(let key in this.showDocumentClassification) {
      if(this.showDocumentClassification[key] == true) {
        return false;
      }
    }
    return true;

  }

  public objectOtherElaborations = {};
  public showDocumentOtherClassification = {};
  arrivedOtherData(objectWithData, i) {

    this.documentsRemained = objectWithData.object.documentsExternal;

    if(!this.objectOtherElaborations[i]) {
      this.objectOtherElaborations[i] = {};
  
      for(let part of objectWithData.object.parts) {
        this.objectOtherElaborations[i][part.partId] = {};
      }
  
    }

    this.objectOtherElaborations[i][objectWithData.object.part.partId][objectWithData.object.docId] = objectWithData.object;

    
      
    this.showDocumentOtherClassification[i] = {};
    this.showDocumentOtherClassification[i][objectWithData.object.part.partId] = {};
    
    this.showDocumentOtherClassification[i][objectWithData.object.part.partId][objectWithData.object.docId] = true;


    this.disableAddDocument = false;


  }

  clickedDeleteData;

  deleteOtherData(objectWithData, i) {

    this.clickedDeleteData = true;



    if(!this.isEmpty(objectWithData.nullable)) {
      if(this.documentsRemained.indexOf(objectWithData.nullable) === -1) {
        this.documentsRemained.push(objectWithData.nullable)
      }
    }

    if(this.objectOtherElaborations[i] && this.objectOtherElaborations[i][objectWithData.object.part.partId]) {
      this.objectOtherElaborations[i][objectWithData.object.part.partId][objectWithData.object.docId] = undefined;
    }

    
      
    this.showDocumentOtherClassification[i] = {};
    this.showDocumentOtherClassification[i][objectWithData.object.part.partId] = {};
    
    this.showDocumentOtherClassification[i][objectWithData.object.part.partId][objectWithData.object.docId] = false;

  }


  public isEmpty(obj) {
    for(var prop in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }


  async terminateProcedure() {

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseLoadingComplete = await this.sessionsService.setSessionStateCompleted(sessionStorage.getItem('sessionId'));

    responseLoadingComplete.subscribe(
      res => {
        // Elimina lo spinner se la risposta è andata bene
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');
        this.elaborationDone = true;
      }, (error) => {
        console.log("ERRORE: ", error)
      }
    )
  }


  async endOfElaboration() {

    this.deleteAll = true;

    sessionStorage.removeItem("objectElaborations")

    location.reload();
  }

  // Nuovo sviluppo
  totalNewCards = 0 // Indica le cards in più rispetto alla prima (che è fissa e ci deve sempre essere)
  disableAddDocument = false;
  public addDocumentToHomepage() {

    this.totalNewCards++;

    this.disableAddDocument = true;

  }


  isEmptyObjectOtherElaborations() {
    for(let key in this.objectOtherElaborations) {
      if(this.objectOtherElaborations[key]) { // Se esiste
        for(let key2 in this.objectOtherElaborations[key]) {
          if(this.objectOtherElaborations[key][key2]) {
            for(let key3 in this.objectOtherElaborations[key][key2]) {
              if(this.objectOtherElaborations[key][key2][key3]) {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }


  removeUniqueOtherCard() {

    if(this.totalNewCards == 1 && this.isEmptyObjectOtherElaborations() && this.clickedDeleteData) {
    
      this.clickedDeleteData = false;
      if(this.totalNewCards > 0) {
        this.totalNewCards--;
      }
      
      return true;
    } else {
      return false;
    }

  }

  reduceNumberOfCards(event) {
    this.totalNewCards--;
  }

    // Metodo che evita un errore di Angular
    ngAfterViewChecked(){ 
      this.cdr.detectChanges();
   }





  

}
