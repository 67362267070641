/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */
 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { ErrorResponse } from '../model/errorResponse';
 import { LogEvents } from '../model/logEvents';
 import { LogGroups } from '../model/logGroups';
 import { PostLogsRequest } from '../model/postLogsRequest';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 

import packageInfo from '../../../../config.json';


@Injectable()
export class LogsService {
    protected basePath = "https://" + packageInfo.apiGatewayUrl + "/" + packageInfo.env + "/" + packageInfo.backOfficeApiContextRoot + "/rest"; //'http://localhost:9085/local/backoffice/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }

        this.defaultHeaders.set("Authorization", "Bearer " + this.getCookie("JWTTOKEN")); // Prende il token JWT dai cookie

    }

    private getCookie(name: string){
        var pattern = RegExp(name + "=.[^;]*")
        var matched = document.cookie.match(pattern)
        if(matched){
            var cookie = matched[0].split('=')
            return cookie[1]
        }
        return false
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GET log groups
     * Servizio che restituisce la lista di log groups disponibili.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLogGroups(observe?: 'body', reportProgress?: boolean): Observable<LogGroups>;
    public getLogGroups(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogGroups>>;
    public getLogGroups(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogGroups>>;
    public getLogGroups(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        headers = new HttpHeaders().set("Authorization", "Bearer " + this.getCookie("JWTTOKEN"));

        return this.httpClient.request<LogGroups>('get',`${this.basePath}/logs/logGroups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * POST log events
     * Servizio che restituisce una lista di eventi filtrata in funzione del query param q. Il valore di q deve essere una stringa di ricerca in formato CloudWatch. Il query param limit è utile alla paginazione (default 10). Una richiesta con limit &#x3D; 10 significa che si richiede la visualizzazione di soli 10 risultati. Il query param logGroups permette di ricercare eventi appartenenti a uno o più specifici logGroup (i nomi dei diversi log groups devono essere separati da virgola. Se nessun logGroup è especificato il servizio ritorna eventi appartenenti a tutti i log groups. I query param start ed end, timestamp UTC, permettono di specificare data di inizio e fine di riferimento. In caso di nessun match trovato, il servizio restituisce lista vuota.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postLogs(body: PostLogsRequest, observe?: 'body', reportProgress?: boolean): Observable<LogEvents>;
    public postLogs(body: PostLogsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogEvents>>;
    public postLogs(body: PostLogsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogEvents>>;
    public postLogs(body: PostLogsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getLogs.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        headers = new HttpHeaders().set("Authorization", "Bearer " + this.getCookie("JWTTOKEN"));

        return this.httpClient.request<LogEvents>('post',`${this.basePath}/logs`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
