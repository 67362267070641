import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { DocumentsService, SessionsService } from '../api';

@Component({
  selector: 'app-document-classification',
  templateUrl: './document-classification.component.html',
  styleUrls: ['./document-classification.component.scss']
})
export class DocumentClassificationComponent implements OnInit {

  @Input('objectElaborations') objectElaborations;
  @Input('toward') toward;

  @Input('left') public left: number;
  @Input('top') public top: number;

  @Input('elaborationDone') elaborationDone;


  documentTitle: string; // Titolo del documento

  mode: ProgressBarMode = 'determinate';
  url; // Immagine visualizzata
  
  bufferValue = 75;


  public image: string = "assets/images/esempioPatente.png";



  constructor(private router: Router,
    private documentsService: DocumentsService
  ) { }

  ngOnInit(): void {
    this.extractLabels(this.objectElaborations.docId);
    this.createTitle();
    this.loadImage();
  }


  public decimalToPercent(value) {
    return Math.round(value * 100)
  }

  public getIconFromElaborationStatus(elaborationStatus) {
    if(elaborationStatus == "SUCCESS") {
      return "fa-check";
    } else if(elaborationStatus == "ERROR") {
      return "fa-exclamation-triangle";
    }
  }

  private loadImage() {
    var reader = new FileReader();
		reader.readAsDataURL(this.objectElaborations.photo);
		reader.onload = (_event) => {
			this.url = reader.result; 
		}
  }

  private createTitle() {
    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    for(const key in documentsTitles) {
      if(documentsTitles[key].documentTypeId == this.objectElaborations['title']) {
        this.documentTitle = documentsTitles[key].externalDescription;
      }
    }
  }

  public goToOCRResultPage() {
    sessionStorage.setItem("objectElaborations", JSON.stringify(this.objectElaborations))
    this.router.navigateByUrl('/ocrResult',  { state: this.objectElaborations });
  }


  // ********** Nuovo sviluppo **************
  predictedLabels
  public async extractLabels(docId) {
    
    let responseGetDocument = await this.documentsService.getDocument(sessionStorage.getItem('sessionId'),docId);
    responseGetDocument.subscribe(
      res => {
        this.predictedLabels = res.predictedLabels;
      }, (err) => {
        console.log("errore")
      }
    )

  }

  public dataURLtoFile(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "File name", {type:mime});
  }

}