<h1>Log</h1>

<div class="searchToolbar">

    <mat-toolbar class="greenNavBar">

        <mat-toolbar-row>
                <input class="searchBar" type="text" placeholder="Inserire la query CloudWatch" aria-label="Search"  matInput [(ngModel)]="q">
                <mat-toolbar-row>
                    <mat-form-field class="dateRangeInput"  appearance="fill">
                        <mat-label>Data di inizio e fine</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Data inizio" [(ngModel)]="selectedDataInizio"> <!-- prende data inizio-->
                            <input matEndDate placeholder="Data fine" [(ngModel)]="selectedDataFine"> <!-- prende data fine-->
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </mat-toolbar-row>

                <div class="example-button-row">
                    <button mat-raised-button color="primary" (click)="searchLogs()"><em class="fa fa-search"></em></button>
                </div>


                
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar class="greenNavBar2">

        <mat-form-field class="logGroupsInput" appearance="fill">
            <mat-label>Log Groups</mat-label>
            <mat-select [formControl]="toppings" multiple [(ngModel)]="selectedLogGroups">
            <mat-select-trigger>
                {{toppings.value ? toppings.value[0] : ''}}
                <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                </span>
            </mat-select-trigger>
            <mat-option *ngFor="let topping of logGroupsNames" [value]="topping">{{topping}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="limitInput" appearance="fill">
            <mat-label>Limite</mat-label>
            <mat-select [formControl]="limits" [(ngModel)]="selectedLimit">
            <mat-option *ngFor="let limitValue of limitValues" [value]="limitValue">{{limitValue}}</mat-option>
            </mat-select>
        </mat-form-field>




    </mat-toolbar>

</div>





<br><br><br>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="Log">

    <ng-container matColumnDef="message">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> message </th>
        <td style="white-space:pre-wrap; word-wrap:break-word" mat-cell *matCellDef="let element">
            <div style="width: 442px;word-wrap: break-word;">
                {{element.message}}
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="eventId">
      <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> eventId </th>
      <td class="cellEventId" mat-cell *matCellDef="let element">{{element.eventId}} </td>
    </ng-container>

    <ng-container matColumnDef="logGroupName">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> logGroupName </th>
        <td mat-cell *matCellDef="let element">{{element.logGroupName}} </td>
    </ng-container>

    <ng-container matColumnDef="logStreamName">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> logStreamName </th>
        <td mat-cell *matCellDef="let element">{{element.logStreamName}}</td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> timestamp </th>
        <td mat-cell *matCellDef="let element"> {{element.timestamp}}</td>
    </ng-container>
   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="center" *ngIf="tokenPresent()">
    <button class="action-button add-log" (click)="moreLogs()">CONTINUA</button>
</div>