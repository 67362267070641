import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GlobalCrossCheckComponent } from './global-cross-check/global-cross-check.component';
import { HomeAlternativeComponent } from './home-alternative/home-alternative.component';
import { HomeRelaborationComponent } from './home-relaboration/home-relaboration.component';
import { HomeComponent } from './home/home.component';
import { InsertSideDocumentComponent } from './insert-side-document/insert-side-document.component';
import { LogComponent } from './log/log.component';
import { LoginComponent } from './login/login.component';
import { OcrResult2Component } from './ocr-result2/ocr-result2.component';
import { OCRResultComponent } from './ocrresult/ocrresult.component';
import { RouteGuardService } from './services/route-guard.service';
import { SessionCheckComponent } from './session-check/session-check.component';
import { SettingsComponent } from './settings/settings.component';
import { StatisticsComponent } from './statistics/statistics.component';


const routes: Routes = [

  { path: "", component: LoginComponent },

  { path: 'home', component: HomeAlternativeComponent, canActivate: [RouteGuardService]},
  { path: 'ocrResult', component: OCRResultComponent, canActivate: [RouteGuardService]},
  { path: 'globalCrossCheck', component: GlobalCrossCheckComponent, canActivate: [RouteGuardService]},
  { path: 'sessionCheck', component: SessionCheckComponent, canActivate: [RouteGuardService]},
  { path: 'log', component: LogComponent, canActivate: [RouteGuardService] },
  { path: 'ocrResultOldSession', component: OcrResult2Component, canActivate: [RouteGuardService] },
  { path: 'settings', component: SettingsComponent, canActivate: [RouteGuardService] },
  { path: 'statistics', component: StatisticsComponent, canActivate: [RouteGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }