import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { InsertSideDocumentComponent } from './insert-side-document/insert-side-document.component';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';


import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import { ChartModule } from 'primeng/chart';

import {MatToolbarModule} from '@angular/material/toolbar';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { OCRResultComponent } from './ocrresult/ocrresult.component';
import { SessionCheckComponent } from './session-check/session-check.component';
import { AppRoutingModule } from './app-routing.module';
import { DocumentExtractionComponent, PopupComponent } from './document-extraction/document-extraction.component';
import { HomeAlternativeComponent } from './home-alternative/home-alternative.component';
import { DocumentClassificationComponent } from './document-classification/document-classification.component';
import { MatCardModule } from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ProgressBarColor} from './directives/progress-bar-color';
import { HeaderComponent } from './header/header.component';
import { ApiModule } from './api';
import { LogComponent } from './log/log.component';
import { MatNativeDateModule } from '@angular/material/core';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './route-re-use';
import { GlobalCrossCheckComponent } from './global-cross-check/global-cross-check.component';
import { LoginComponent } from './login/login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeRelaborationComponent } from './home-relaboration/home-relaboration.component';
import { HandlerHttpService } from './handler.http.service';
import { OcrResult2Component } from './ocr-result2/ocr-result2.component';
import { SettingsComponent } from './settings/settings.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InsertSideDocumentComponent,
    OCRResultComponent,
    SessionCheckComponent,
    DocumentExtractionComponent,
    HomeAlternativeComponent,
    DocumentClassificationComponent,
    ProgressBarColor,
    HeaderComponent,
    LogComponent,
    PopupComponent,
    GlobalCrossCheckComponent,
    LoginComponent,
    HomeRelaborationComponent,
    OcrResult2Component,
    SettingsComponent,
    StatisticsComponent
],
  imports: [

    
    BrowserModule,
    HttpClientModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatTableModule,
    MatToolbarModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    AppRoutingModule,
    MatRadioModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    FlexLayoutModule,
    AccordionModule,
    ChartModule,
    ApiModule,
    MatGridListModule,  
    LayoutModule,
    PdfViewerModule,
    PdfJsViewerModule,
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    })
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandlerHttpService,
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
