import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private OCRList: string[] = [];



  constructor() { }

  /*
  public getOCRList(): string[] {
    return this.OCRList;
  }
  */

  ngOnInit(): void {
    //this.OCRList.push('google - Google Vision API');
    //this.OCRList.push('textract - Amazon Textract');
  }
  

  



}
