<h1>BlueScan Extraction Test Webapp</h1>

<div *ngIf="isDataLoaded">



<div class="card mt-4" style="width:100%;">
	<div class="card-header">
		<span class="bold">{{documentTitle | uppercase}}</span> - {{dataFromDocumentClassification.toward}}
		<button class="action-button reprocess-documento" (click)="goToReprocessDocument()"><strong>RIPROCESSA DOCUMENTO</strong></button>
	</div>

	<div class="card-body external-body">
		<div class="ocr-string card-header accordion ui-accordion ui-widget ui-helper-reset" data-document="DRIVER_LICENSE" data-direction="F" role="tablist">

			<div *ngFor="let elaboration of this.dataFromDocumentClassification.elaborations">

				<mat-expansion-panel hideToggle>
					<mat-expansion-panel-header>
					<mat-panel-title>
						<!--Title-->
						<h3 class="ui-accordion-header ui-corner-top ui-state-default ui-accordion-icons ui-accordion-header-active ui-state-active" role="tab" id="ui-id-1" aria-controls="ui-id-2" aria-selected="true" aria-expanded="true" tabindex="0">
							<span class="ui-accordion-header-icon ui-icon ui-icon-triangle-1-s"></span>Stringa Ocr - Elaborazione {{elaboration.elabId}} - Filtro {{elaboration.filterType}} - Provider {{elaboration.ocrProvider}}
						</h3>
					</mat-panel-title>
					<mat-panel-description>
						<!-- This is a summary of the content -->
					</mat-panel-description>
					</mat-expansion-panel-header>
					<!--This is the primary content of the panel.-->
					<div class="row">
						<div class="col-md-10">
							<!-- Colonna 1 -->
							<strong>Risultato grezzo dell'estrazione: </strong>
							{{elaboration.ocrString}}
						</div>

					</div>
				</mat-expansion-panel>

				<br/>

			</div>

		








		<div class="row card-group" data-document="DRIVER_LICENSE" data-direction="F">


			<div *ngFor="let elaboration of this.dataFromDocumentClassification.elaborations; let i = index" class="card inner-card col-md-3" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
				<div class="card-header">
					
					<div class="row">

						<div class="col-9">
							<span><strong>{{elaboration.filterType}}</strong></span>
						</div>

						<div class="col-2">
							<img alt="Successo" *ngIf="elaboration.elaborationState == 'SUCCESS'" class="card-status-icon" [src]="imageSuccess">
                            <img alt="Errore" *ngIf="elaboration.elaborationState != 'SUCCESS'" class="card-status-icon" [src]="imageError">
						</div>

					</div>

					<br/>
					
			
					<div class="right-elab-state d-inline-flex" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
						
					<!-- Fare apparire l'immagine giusta-->	

					<div *ngIf="typeActualDocument !== 'PDF'">
						<img alt="Elaborazione documento" class="elab-state-img" [src]="imagesElaborations[elaboration.elabId]">
					</div>

					<div *ngIf="typeActualDocument === 'PDF'">
						<ng2-pdfjs-viewer [pdfSrc]="dataURLtoFile(imagesElaborations[elaboration.elabId])" [page]=1></ng2-pdfjs-viewer>
					</div>

					<!--<img class="elab-state-img" [src]="">-->
					
					</div>
					</div>

					<div class="img-card-body" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
						<img alt="Documento" class="card-img-top img-document" data-document="DRIVER_LICENSE" data-direction="F">
					</div>
						<div class="inner-card-body card-body" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
							
							<div *ngFor="let entity of elaboration.entities">

								<div>
									<span class="bold">{{entity.key}}</span>: {{entity.value}} - 
									<span class="bold green">{{entity.confidence}}</span> 
								</div>

					
							</div>
							

						</div>
					</div>

					



									
					<div class="card col-md-3">
						<div class="card-header">
							
							<div class="row">

								<div class="col-11">
									<span><strong>Risultato Cross Check</strong></span>
								</div>

		
							</div>
							
							<br/>

						</div>
						<div class="img-card-body img-cross-check" data-document="DRIVER_LICENSE" data-direction="F">
							<img alt="Cross Check" [src]="result" class="result-image card-img-top" data-document="DRIVER_LICENSE" data-direction="F" style="width: 224px; height: 167px;">
						</div>
						<div class="card-body ocr-cross-check" data-document="DRIVER_LICENSE" data-direction="F">

							<div *ngFor="let entity of localCrossCheckData.entities">

								<div>
									<span class="bold">{{entity.key}}</span>: {{entity.value}} - 
									<span class="bold green">{{entity.confidence}}</span> 
								</div>

					
							</div>



							
						</div>


						</div>
					</div>
					
				</div>
			</div>

							
</div>

<div class="row center">
	<div class="col-md-12">
		<div class="centerButton"><button  class="action-button buttonAfterOcr" routerLink="/home"><strong>Torna all'elaborazione</strong></button></div>
	</div>
	
</div>

