import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  originalSize = false;

  constructor() { }

  ngOnInit(): void {
    this.originalSize = false
  }

  changedOriginalSize() {
    sessionStorage.setItem("originalSize", JSON.stringify(this.originalSize))
  }
}
