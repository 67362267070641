

<div class="title">
    Documenti per la sessione: <strong>{{this.dataOld.sessionId}}</strong>
</div>

<div *ngIf="CompletedElaboration">




<div *ngIf="oldDocuments">
<div class="card mt-4" style="width:100%;" *ngFor="let document of oldDocuments">
    
    
    
    <div class="card-header">
		<span class="bold">{{getExternalDescriptionByPartId(document.documentType)}}</span> - {{returnToward(document.documentToward)}}
        <button class="action-button reprocess-documento" (click)="goToReprocessDocument(document)"><strong>RIPROCESSA DOCUMENTO</strong></button>
	</div>

    <div *ngIf="document.forcedClassification" class="forcedDocumentAdvertising">
        Questo documento è stato riconosciuto come appartenente ad un'altra categoria. L'utente ha però confermato che si tratta di <strong>{{getExternalDescriptionByPartId(document.documentType)}} {{returnToward(document.documentToward)}}</strong>.
    </div>


    <div class="card-body external-body">
		<div class="ocr-string card-header accordion ui-accordion ui-widget ui-helper-reset" data-document="DRIVER_LICENSE" data-direction="F" role="tablist">
            <div *ngIf="document.elaborations">
                <div *ngFor="let elaboration of document.elaborations">
                    
                    
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            <!--Title-->
                            <h3 class="ui-accordion-header ui-corner-top ui-state-default ui-accordion-icons ui-accordion-header-active ui-state-active" role="tab" id="ui-id-1" aria-controls="ui-id-2" aria-selected="true" aria-expanded="true" tabindex="0">
                                <span class="titleElaboration">Stringa Ocr - Elaborazione {{elaboration.elabId}} - Filtro {{elaboration.filterType}} - Provider {{elaboration.ocrProvider}}</span>
                            </h3>
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-md-10">
                                <!-- Colonna 1 -->
                                <strong>Risultato grezzo dell'estrazione: </strong>
                                {{elaboration.ocrString}}
                            </div>

                        </div>
                    </mat-expansion-panel>

                </div>
            </div>
        </div>








        <div class="row card-group" data-document="DRIVER_LICENSE" data-direction="F">
            <div *ngFor="let elaboration of document.elaborations; let i = index" class="card inner-card col-md-4" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
                <div class="card-header">
                    <div class="row">
                        <div class="col-9">
							<span><strong>{{elaboration.filterType}}</strong></span>
						</div>

						<div class="col-2">
							<img alt="Elaborazione avvenuta con successo" *ngIf="elaboration.elaborationState == 'SUCCESS'" class="card-status-icon" [src]="imageSuccess">
                            <img alt="Errore nell'elaborazione" *ngIf="elaboration.elaborationState != 'SUCCESS'" class="card-status-icon" [src]="imageError">
						</div>
                    </div>

                    <br/>

                    <div>

                        
                        <div *ngIf="elaboration && elaboration.image && !elaboration.pdf" class="right-elab-state d-inline-flex">
                            <img alt="Elaborazione immagine" class="elab-state-img" [src]="elaboration.image">
                        </div>
                        <div *ngIf="elaboration && !elaboration.image && elaboration.pdf" class="viewer-pdf">
                            <ng2-pdfjs-viewer [pdfSrc]="elaboration.pdf" [page]=1></ng2-pdfjs-viewer>
                        </div>


                        

                    </div>
                </div>

                <div class="img-card-body" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
                    <img alt="Documento" class="card-img-top img-document" data-document="DRIVER_LICENSE" data-direction="F">
                </div>
                <div class="inner-card-body card-body" data-document="DRIVER_LICENSE" data-direction="F" data-filtertype="NO_FILTER">
                    <div *ngIf="elaboration">
                        <div *ngIf="elaboration.entities">
                            <div *ngFor="let entity of elaboration.entities">
                                <div>
                                    <span class="bold">{{entity.key}}</span>: {{entity.value}} - 
                                    <span class="bold green">{{entity.confidence}}</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card col-md-3" *ngIf="dataOld.state == 'CLOSED' || dataOld.state == 'COMPLETED'">
                <div class="card-header">
                    <div class="row">
                        <div class="col-11">
                            <span><strong>Risultato Cross Check</strong></span>
                        </div>
                    </div>
                </div>
                <div class="img-card-body img-cross-check" data-document="DRIVER_LICENSE" data-direction="F">
                    <img alt="Cross Check" [src]="result" class="elab-state-img">
                </div>
                <div class="card-body ocr-cross-check" data-document="DRIVER_LICENSE" data-direction="F">
                    <div *ngIf="document">
                        <div *ngIf="document.localCrossCheck">
                            <div *ngIf="document.localCrossCheck.entities">
                                <div *ngFor="let entity of document.localCrossCheck.entities">
                                    <div>
                                        <span class="bold">{{entity.key}}</span>: {{entity.value}} - 
                                        <span class="bold green">{{entity.confidence}}</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    



        </div>

        



    </div>


    

</div>
</div>

    <div class="row center" *ngIf="dataOld.state == 'CLOSED' || dataOld.state == 'COMPLETED'">
        <div class="col-md-12">
            <div><button class="action-button buttonForGlobalCrossCheck" (click)="doGlobalCrossCheck(this.dataOld.sessionIdEncoded)"><strong>RISULTATO FINALE</strong></button></div>
        </div>
    </div>

    <div *ngIf="docsCrossCheck">
        <!-- Qui dentro la tabella che mostra la crosscheck globale -->

        <hr>

            

            <div *ngFor="let singleDoc of docsCrossCheck">
                <div id="cross-check-container">
                    <div id="global-cross-check" class="card border-success">
                        <div class="bold card-header">{{getExternalDescriptionByDocumentId(singleDoc.docType)}} - <span class="bold" [ngClass]="{'green': singleDoc.documentEntities.confidenceIndex >= 0.80, 'red' : singleDoc.documentEntities.confidenceIndex < 0.80 }"> {{singleDoc.documentEntities.confidenceIndex}}</span></div>
                    <div class="card-body global-body">
                        <div *ngFor="let entity of singleDoc.documentEntities.entities">
                            <p><span class="bold">{{entity.key}}</span>: {{entity.value}} - <span [ngClass]="{'green': entity.confidence >= 0.80, 'red' : entity.confidence < 0.80 }">{{entity.confidence}}</span></p>
                            <p></p>
                        </div>
                    </div>
                    </div>
                </div>
    
                <hr>

            </div>
            


        <div id="cross-check-container">
            <div id="global-cross-check" class="card border-success">
               <div class="card-header bg-transparent border-success"><span class="bold">Global Cross Check - </span><span class="bold" [ngClass]="{'green': globalCrossCheck.confidenceIndex >= 0.80, 'red' : globalCrossCheck.confidenceIndex < 0.80 }">{{globalCrossCheck.confidenceIndex}}</span></div>
               <div class="card-body global-body">
                  <div *ngFor="let entity of globalCrossCheck.entities">
                    <p><span class="bold">{{entity.key}}</span>: {{entity.value}} - <span [ngClass]="{'green': entity.confidence >= 0.80, 'red' : entity.confidence < 0.80 }">{{entity.confidence}}</span></p>
                 </div>
               </div>
            </div>
        </div>



    </div>

</div>



<div class="row center">
	<div class="col-md-12">
		<div class="centerButton"><button  class="action-button buttonAfterOcr" routerLink="/sessionCheck"><b>Torna alle sessioni</b></button></div>
	</div>
</div>

