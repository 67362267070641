export * from './configurations.service';
import { ConfigurationsService } from './configurations.service';
export * from './crossCheck.service';
import { CrossCheckService } from './crossCheck.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './elaborations.service';
import { ElaborationsService } from './elaborations.service';
export * from './logs.service';
import { LogsService } from './logs.service';
export * from './services.service';
import { ServicesService } from './services.service';
export * from './sessions.service';
import { SessionsService } from './sessions.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export const APIS = [ConfigurationsService, CrossCheckService, DocumentsService, ElaborationsService, LogsService, ServicesService, SessionsService, StatisticsService];
