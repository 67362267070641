import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


import { LoginService } from './login.service';


import * as AWS from 'aws-sdk';

import packageInfo from '../../../config.json';

AWS.config.update({
  region: packageInfo.awsRegion, // TODO: sostituire con stringa nel config  
});


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup: FormGroup;
  formGroupChangePassword: FormGroup;

  wrongLogin: boolean = false;
  wrongNewPassword: boolean = false;

  changePassword: boolean = false

  warningMessage: string;
  warningMessagePassword: string;

  changedPassword: boolean = false;

  normalLogin: boolean = true; // A true quando non è abilitato il cambio password

  constructor(private loginService : LoginService,
              private router: Router) { }



  classicAuthentication() {

    var cognito = new AWS.CognitoIdentityServiceProvider();

    var authParams = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: packageInfo.awsCognitoClientId,
      AuthParameters: {
          "USERNAME": this.formGroup.controls.username.value,
          "PASSWORD": this.formGroup.controls.password.value
      }
    };

    return new Promise(function(res) {
        cognito.initiateAuth(authParams, function(err, result) {

          if (err)
          {
            res(err);
          } else if(result) {
            res(result);
          }

        });
    })
  }

  changePasswordCognito() {

    var cognito = new AWS.CognitoIdentityServiceProvider();

    var params = {
      ChallengeName: "NEW_PASSWORD_REQUIRED",
      ClientId: packageInfo.awsCognitoClientId, 

      ChallengeResponses: {
        'USERNAME': this.formGroup.controls.username.value,
        'NEW_PASSWORD': this.formGroupChangePassword.controls.newPassword.value,
      },
      Session: sessionStorage.getItem("Session")

    };

    return new Promise(function(res) {
        cognito.respondToAuthChallenge(params, function(err, result) {

          if (err)
          {
            res(err);
          } else if(result) {
            res(result);
          }

        });
    })
  }






















  

  async tryToAutenticate() {

    this.classicAuthentication().then(
      res => {


        if(res.hasOwnProperty("AuthenticationResult")) { // Il login dovrebbe essere andato a buon fine


          var t = new Date();
          t.setSeconds(t.getSeconds() + 3600);

          document.cookie = "JWTTOKEN" + "=" + res["AuthenticationResult"].IdToken + " ; expires=" + t.toUTCString() + "; path=/"; // Metti nei cookie il TOKEN JWT
          document.cookie = "REFRESHTOKEN" + "=" + res["AuthenticationResult"].RefreshToken + " ; expires=" + t.toUTCString() + "; path=/"; // Metti nei cookie il TOKEN JWT


          //this.loginService.correctLogin = true;
          
          sessionStorage.setItem("correctLogin","true")


          sessionStorage.setItem("loggedUser", this.formGroup.controls.username.value)
          

          this.wrongLogin = false;
          this.router.navigate(["/home"]); // Vai alla homepage
          //this.navigateToHomepage();
        } else if(res.hasOwnProperty("ChallengeName") && res["ChallengeName"] == "NEW_PASSWORD_REQUIRED") { // CASO DEL CAMBIO PASSWORD

          sessionStorage.setItem("correctLogin","false")
          sessionStorage.setItem("Session",res["Session"])

          this.changePassword = true;
          this.wrongLogin = true;
          this.warningMessage = "Attenzione. La password attuale non è più valida. Va cambiata la password"
        
        
        
        } else  { // Il login dovrebbe essere fallito. TODO: fare il caso in cui c'è da ricambiare la password
          //this.loginService.correctLogin = false;
          sessionStorage.setItem("correctLogin","false")

          this.wrongLogin = true;
          this.warningMessage = "Attenzione: username o password errati!" 
        }




        
        
      }
    )
    
  }


  changePasswordFlow() {
    this.normalLogin = false;


  }

  confirmNewPassword() {


    if( // Casi di errore
      this.formGroupChangePassword.controls.newPassword.value == "" &&
      this.formGroupChangePassword.controls.newPasswordConfirm.value == "") {
        this.wrongNewPassword = true;
        this.warningMessagePassword = "Attenzione. È obbligatorio inserire la nuova password."
      } else if(this.formGroupChangePassword.controls.newPassword.value != this.formGroupChangePassword.controls.newPasswordConfirm.value) {
      this.wrongNewPassword = true;
      this.warningMessagePassword = "Attenzione. Le due password inserite non sono le stesse."
    } else { // Posso procedere al cambio di password
      this.wrongNewPassword = false;
      this.warningMessagePassword = "";


      this.changePasswordCognito().then(
        res => {
          
          if(res.hasOwnProperty("AuthenticationResult")) {
            this.wrongNewPassword = false;
            this.changedPassword = true;
          } else { // Errori nel cambio password
            this.wrongNewPassword = true;
            this.warningMessagePassword = "Si è verificato un errore. Riprova con una password diversa."
          }

        }, (error) => {

        }
      )

    }
 
  }



  initForm() {
    this.formGroup = new FormGroup({
      username: new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required])
    })

    this.formGroupChangePassword  = new FormGroup({
      newPassword: new FormControl('',[Validators.required]),
      newPasswordConfirm: new FormControl('',[Validators.required]),
    })
  }


  async ngOnInit() {
    this.initForm();

    
  }


  loginProcess() {
    if(this.formGroup.valid) {
      // Fai la chiamata a COGNITO usando il servizio LoginService
      //    => Se hai successo
      //    => Se hai fallito
    }
  }

  cambioPassword() {

  }

  changeNormalLogin() {
    this.normalLogin = !this.normalLogin;
    this.changedPassword = false;
    this.changePassword = false;
    this.wrongLogin = false;
  }

}
