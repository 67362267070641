<h1>Statistiche</h1>

<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Modello">
    <div class="grid-container">

      <div class="row mainTitle">
        <h3><strong>Precisione</strong></h3>
        <button (click)="fileDownloadModelloPrecisione()" class="btn"><i class="fa fa-download"></i> DOWNLOAD CSV</button>
      </div>
  
      <mat-grid-list cols="2" rowHeight="550px">
  
          <mat-grid-tile *ngFor="let card of precisionCardsModello" [colspan]="1" [rowspan]="1">
          <mat-card class="dashboard-card ">
            <mat-card-header>
              <mat-card-title>
                <b>{{createTitle(card.documentTypeId)}}</b>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="dashboard-card-content">
                <div class="row graphicRow">
  
                  <div class="chart centerGraph col-md-6" *ngFor="let side of card.parts" style="height: 300px">
                        <div><strong>{{returnToward(side.code)}}</strong></div>
                        <circle-progress
                          [percent]="percentage(side.notForcedNumber, side.totalNumber)"
                          [radius]="100"
                          [outerStrokeWidth]="16"
                          [innerStrokeWidth]="8"
                          [outerStrokeColor]="'#78C000'"
                          [innerStrokeColor]="'#C7E596'"
                          [animation]="true"
                          [animationDuration]="300"
                          [showSubtitle]="false"
                          [showInnerStroke]="true"
                        ></circle-progress>
                        <div><strong>Percentuale documenti correttamente riconosciuti</strong></div>
                  </div>
  
  
                </div>
              
            </mat-card-content>
          </mat-card>
          </mat-grid-tile>
  
      </mat-grid-list>

      <div class="row marginTitle">
        <h3><strong>Affidabilità</strong></h3>
        <button (click)="fileDownloadModelloAffidabilita()" class="btn"><i class="fa fa-download"></i> DOWNLOAD CSV</button>
      </div>
      
      <br>

      <div class="row">
        <mat-form-field appearance="fill" class="selectChooseThreshold">
          <mat-label>Inserire una soglia</mat-label>
          <mat-select [(value)]="selectedThresholdModello" (selectionChange)="modifyDataForSpecificThresholdModello()">
            <div *ngFor="let object of reliabilityGreaterThanThresholdObjectsModello">
              <mat-option [value]="object.threshold">{{object.threshold}}</mat-option>
            </div>
          </mat-select>
      </mat-form-field>      
     </div>
  
      <mat-grid-list cols="2" rowHeight="550px">

          <mat-grid-tile *ngFor="let card of actualCardReliabilityModello" [colspan]="1" [rowspan]="1">
          <mat-card class="dashboard-card">
            <mat-card-header>
              <mat-card-title>
                <b>{{createTitle(card.documentTypeId)}}</b>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="dashboard-card-content">
                <div class="row graphicRow">
                  <div class="chart centerGraph col-md-6" *ngFor="let side of card.parts" style="height: 300px">
                    <div><strong>{{returnToward(side.code)}}</strong></div>
                    <circle-progress
                      [percent]="percentage(side.notForcedNumber, side.totalNumber)"
                      [radius]="100"
                      [outerStrokeWidth]="16"
                      [innerStrokeWidth]="8"
                      [outerStrokeColor]="'#1c4e80'"
                      [innerStrokeColor]="'#68a9c5'"
                      [animation]="true"
                      [animationDuration]="300"
                      [showSubtitle]="false"
                      [showInnerStroke]="true"
                    ></circle-progress>
                    <div><strong>Percentuale documenti correttamente riconosciuti</strong></div>
                  </div>
                </div>
              
            </mat-card-content>
          </mat-card>
          </mat-grid-tile>

      </mat-grid-list>
    </div>
  </mat-tab>


  <!------------------------------------------------------------------------------------------------------------------------------------>
  <!------------------------------------------------------------------------------------------------------------------------------------>
  <!------------------------------------------------------------------------------------------------------------------------------------>
  <!------------------------------------------------------------------------------------------------------------------------------------>
  <!------------------------------------------------------------------------------------------------------------------------------------>

  <mat-tab label="Estrazione">
    <div class="grid-container">

      <div class="row mainTitle">
        <h3><strong>Precisione</strong></h3>
        <button (click)="fileDownloadEstrazionePrecisione()" class="btn"><i class="fa fa-download"></i> DOWNLOAD CSV</button>
      </div>

      <mat-grid-list cols="3" rowHeight="550px">
        <div *ngFor="let card of precisionCardsEstrazione">
          <span *ngIf="createTitle(card.documentType)">
            <mat-grid-tile [colspan]="1" [rowspan]="1">
                <mat-card class="dashboard-card">
              
                  <mat-card-header>
                    <mat-card-title>
                      <b>{{createTitle(card.documentType)}}</b>
                    </mat-card-title>
                </mat-card-header>


                <mat-card-content class="dashboard-card-content">
                  <div class="row graphicRow">

                    <div class="chart col-md-12" style="height: 300px">

                      <circle-progress
                        [percent]="percentage(card.partialNumber, card.totalNumber)"
                        [radius]="100"
                        [outerStrokeWidth]="16"
                        [innerStrokeWidth]="8"
                        [outerStrokeColor]="'#fa374e'"
                        [innerStrokeColor]="'#f77786'"
                        [animation]="true"
                        [animationDuration]="300"
                        [showSubtitle]="false"
                        [showInnerStroke]="true"
                      ></circle-progress>
                      <div class="descriptionEstrazione"><strong>Entità estratte correttamente</strong></div>
                    
                    </div>
                  </div>
                
              </mat-card-content>
              </mat-card>
            </mat-grid-tile>
        </span>
      </div>
      </mat-grid-list>


      <div class="row marginTitle">
        <h3><strong>Correttezza</strong></h3>
        <button (click)="fileDownloadEstrazioneCorrettezza()" class="btn"><i class="fa fa-download"></i> DOWNLOAD CSV</button>
      </div>


      <mat-grid-list cols="3" rowHeight="550px">
        <div *ngFor="let card of correctnessCardsEstrazione" >
          <span *ngIf="createTitle(card.documentType)">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-card class="dashboard-card">
          
              <mat-card-header>
                <mat-card-title>
                  <strong>{{createTitle(card.documentTypeId)}}</strong>                
                </mat-card-title>
             </mat-card-header>

             <mat-card-content class="dashboard-card-content">
              <div class="row graphicRow">
                <div class="chart col-md-12" style="height: 300px">
                  <circle-progress
                    [percent]="percentage(card.partialNumber, card.totalNumber)"
                    [radius]="100"
                    [outerStrokeWidth]="16"
                    [innerStrokeWidth]="8"
                    [outerStrokeColor]="'#d4a420'"
                    [innerStrokeColor]="'#e0b94c'"
                    [animation]="true"
                    [animationDuration]="300"
                    [showSubtitle]="false"
                    [showInnerStroke]="true"
                  ></circle-progress>
                  <div class="descriptionEstrazione"><strong>Documenti con almeno un'estrazione corretta</strong></div>
                </div>
              </div>
            
          </mat-card-content>
          </mat-card>
        </mat-grid-tile>
      </span>
      </div>
      </mat-grid-list>

      <div class="row marginTitle">
        <h3><strong>Affidabilità</strong></h3>
        <button (click)="fileDownloadEstrazioneAffidabilita()" class="btn"><i class="fa fa-download"></i> DOWNLOAD CSV</button>
      </div>

      <br>

      <mat-form-field appearance="fill" class="selectChooseThreshold">
        <mat-label>Inserire una soglia</mat-label>
        <mat-select [(value)]="selectedThresholdAffidabilitaEstrazione" (selectionChange)="modifyDataForSpecificThresholdAffidabilitaEstrazione()">
          <div *ngFor="let object of reliabilityGreaterThanThresholdObjectsEstrazione">
            <mat-option [value]="object.threshold">{{object.threshold}}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>

    <mat-grid-list cols="3" rowHeight="550px">
  
      <mat-grid-tile *ngFor="let card of actualCardReliabilityEstrazione" [colspan]="1" [rowspan]="1">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            <b>{{createTitle(card.documentType)}}</b>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div class="row graphicRow">
            <div class="chart col-md-12" style="height: 300px">
              <circle-progress
                [percent]="percentage(card.partialNumber, card.totalNumber)"
                [radius]="100"
                [outerStrokeWidth]="16"
                [innerStrokeWidth]="8"
                [outerStrokeColor]="'#ffd900'"
                [innerStrokeColor]="'#ffed87'"
                [animation]="true"
                [animationDuration]="300"
                [showSubtitle]="false"
                [showInnerStroke]="true"
              ></circle-progress>
              <div class="descriptionEstrazione"><strong>Entità estratte con una affidabilità maggiore della soglia {{this.selectedThresholdAffidabilitaEstrazione}}</strong></div>
            </div>
          </div>
        
      </mat-card-content>
      </mat-card>
      </mat-grid-tile>

    </mat-grid-list>


    <div class="row marginTitle">
      <h3><strong>Similarità</strong></h3>
      <button (click)="fileDownloadEstrazioneSimilarita()" class="btn"><i class="fa fa-download"></i> DOWNLOAD CSV</button>
    </div>

    <br>
    
    <mat-form-field appearance="fill" class="selectChooseThreshold">
      <mat-label>Inserire una soglia</mat-label>
      <mat-select [(value)]="selectedThresholdSimilaritaEstrazione" (selectionChange)="modifyDataForSpecificThresholdSimilaritaEstrazione()">
        <div *ngFor="let object of similarity">
          <mat-option [value]="object.threshold">{{object.threshold}}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-grid-list cols="3" rowHeight="550px">
      <div *ngFor="let card of actualCardSimilarityEstrazione">
        <span *ngIf="createTitle(card.documentType)">
      <mat-grid-tile  [colspan]="1" [rowspan]="1">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            <b>{{createTitle(card.documentType)}}</b>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div class="row graphicRow">
            <div class="chart col-md-12" style="height: 300px">
              <circle-progress
                [percent]="percentage(card.partialNumber, card.totalNumber)"
                [radius]="100"
                [outerStrokeWidth]="16"
                [innerStrokeWidth]="8"
                [outerStrokeColor]="'#9000ff'"
                [innerStrokeColor]="'#d6a1ff'"
                [animation]="true"
                [animationDuration]="300"
                [showSubtitle]="false"
                [showInnerStroke]="true"
              ></circle-progress>
              <div class="descriptionEstrazione"><strong>Grado di similarità sul totale di elementi restituiti maggiore della soglia {{this.selectedThresholdSimilaritaEstrazione}}</strong></div>
            </div>
          </div>
        
      </mat-card-content>
      </mat-card>

      </mat-grid-tile>
    </span>
  </div>
    </mat-grid-list>
    




    </div>
    
  </mat-tab>
</mat-tab-group>