

Clicca qui per avviare l'elaborazione:

<button (click)="pollingElaboration()" class="action-button file-start-upload">
    <em class="fa fa-upload"></em> Carica
</button>

<section class="progressBar">
    <mat-progress-bar 
        [appProgressBarColor]="'#28a745'"
        [mode]="modeProgressBar">
    </mat-progress-bar>
</section>


<div *ngIf="showCard">
    <app-document-classification
    [left]="10" 
    [top]="10"
    [objectElaborations]="objectElaborations"
    [toward]="'Fronte'"
    [elaborationDone]="true"
    ></app-document-classification>
</div>

