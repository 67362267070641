import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userLogged = sessionStorage.getItem("loggedUser");

  imLogged: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.userLogged = sessionStorage.getItem("loggedUser");
  }

  logout() {
    if(sessionStorage.getItem("correctLogin") == "true") {

      this.imLogged = true;

      sessionStorage.setItem("correctLogin", "false")
      
      this.router.navigateByUrl("/");
    }
  }

  isDisabled() {
    if(sessionStorage.getItem("correctLogin") == "true") {
      return false;
    } else {
      return true;
    }
    
  }

}
