import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable()
export class HandlerHttpService implements HttpInterceptor {


  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            
          }
          
          return event;
      }),
      catchError(err => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    
    
    if (err.status === 401) {
      
      sessionStorage.setItem("correctLogin","false")
      var linkToRedirect = "/" // Torna alla pagina di login
 
      setTimeout(function(){ 
        window.location.href = linkToRedirect;
      },3000);
      

    }

    return throwError(err);
  }
}
