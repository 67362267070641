<div *ngIf="normalLogin" class="loginComponent"  fxLayoutAlign="center center" class="main-div">
    <mat-card fxFlex="25">
        <mat-toolbar color="primary">Login Page</mat-toolbar>

        <form  fxLayoutAlign="stretch" fxLayout="column" class="login-form" [formGroup]="formGroup" (ngSubmit)="loginProcess()">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Username" formControlName="username">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <input matInput type="password" placeholder="Password" formControlName="password">
            </mat-form-field>

            <button (click)="tryToAutenticate();" mat-raised-button type="submit">Login</button>

            <div style="color: red; text-align: center; margin-top: 10px; margin-bottom: 10px;" *ngIf="wrongLogin">{{warningMessage}}</div>

            <button *ngIf="changePassword" (click)="changePasswordFlow()" mat-raised-button>Cambio password</button>


        </form>
    </mat-card>


</div>


<div *ngIf="!normalLogin" class="loginComponent"  fxLayoutAlign="center center" class="main-div">
    <mat-card fxFlex="25">
        <mat-toolbar color="primary">Cambio Password</mat-toolbar>

        <div class="center">Scegli una nuova password per <strong>{{this.formGroup.controls.username.value}}</strong></div>

        <form  fxLayoutAlign="stretch" fxLayout="column" class="login-form" [formGroup]="formGroupChangePassword">
            <mat-form-field class="example-full-width">
                <input [disabled]="changedPassword" type="password" matInput placeholder="Inserisci nuova password" formControlName="newPassword">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <input [disabled]="changedPassword" type="password" matInput placeholder="Conferma nuova password" formControlName="newPasswordConfirm">
            </mat-form-field>


            <div style="color: red; text-align: center; margin-top: 10px; margin-bottom: 10px;" *ngIf="wrongNewPassword">{{warningMessagePassword}}</div>

            <div *ngIf="changedPassword" style="color: green; text-align: center; margin-top: 10px; margin-bottom: 10px;">Password cambiata con successo</div>
            <!---->
            <button *ngIf="!changedPassword" (click)="confirmNewPassword();" mat-raised-button type="submit">Conferma nuova password</button>

            <button (click)="changeNormalLogin()" style="margin-top: 10px;" mat-raised-button type="submit">Torna al login</button>


        </form>
    </mat-card>


</div>