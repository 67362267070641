import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CrossCheckService, DocumentsService, ElaborationsService } from '../api';

@Component({
  selector: 'app-ocrresult',
  templateUrl: './ocrresult.component.html',
  styleUrls: ['./ocrresult.component.scss']
})
export class OCRResultComponent implements OnInit {

  // Servono per lo spinner
  element;
  loading;

  

  patenteFronte: string = "assets/images/PATENTE_FRONTE.jpg";

  patenteNegativo: string = "assets/images/PATENTE_NEGATIVO.jpg";

  result: string = "assets/images/result.jpg";

  imageSuccess: string = "assets/images/success.png"

  imageError: string = "assets/images/error.png"



  // Campi dati definitivi

  isDataLoaded = false;

  dataFromDocumentClassification;

  documentTitle;

  imagesAfterElaborations = [];

  localCrossCheckData;
  
  //globalCrossCheckData;


  constructor(private router: Router,
              private documentService: DocumentsService,
              private elaborationsService: ElaborationsService,
              private crossCheckService: CrossCheckService) {

                this.element = document.getElementById('container');
                this.loading = document.getElementById('loading');

                this.dataFromDocumentClassification =  this.router.getCurrentNavigation().extras.state;

                if(!this.dataFromDocumentClassification) {
                  this.dataFromDocumentClassification = JSON.parse(sessionStorage.getItem("objectElaborations"))
                }

  }

  ngOnInit(): void {


    this.createTitle();
    this.createPreviewImages(sessionStorage.getItem("sessionId"));

    this.doLocalCrossCheck();

    //this.doGlobalCrossCheck();
    
  }


  private createTitle() {
    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    for(const key in documentsTitles) {
      if(documentsTitles[key].documentTypeId == this.dataFromDocumentClassification['title']) {
        this.documentTitle = documentsTitles[key].externalDescription;
      }
    }
  }

  private async doLocalCrossCheck() {

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseLocalCrossCheck = await this.crossCheckService.getDocumentCrossCheck(sessionStorage.getItem('sessionId'), this.dataFromDocumentClassification.docId)
    responseLocalCrossCheck.subscribe(
      res => {

        // Elimina lo spinner se la risposta è andata bene
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

        this.localCrossCheckData = res;
        this.isDataLoaded = true;
      }, (error) => {
        console.log("ERRORE LOCAL CROSSCHECK: ", error)
      }
    )

  }

  imagesElaborations = {};
  typeActualDocument = null;

  private async createPreviewImages(sessionId) {


    for(const elaboration in this.dataFromDocumentClassification.elaborations) {

      let typeOfDocument = "";

      // Recupera le informazioni sull'estensione del documento
      let responseGetDocument = await this.documentService.getDocument(sessionId, this.dataFromDocumentClassification.elaborations[elaboration].docId);
      responseGetDocument.subscribe(
        res => {


          typeOfDocument = res.extension === 'pdf' ? 'application/pdf' : 'image/'+res.extension;
          let responsePreview = this.elaborationsService.getContent(sessionId, this.dataFromDocumentClassification.elaborations[elaboration].docId, this.dataFromDocumentClassification.elaborations[elaboration].elabId, typeOfDocument, sessionStorage.getItem("originalSize") === 'true' ? true : false);
          responsePreview.subscribe(
            res => {


              let base64CompletePreview = "data:"+typeOfDocument+";base64,"+this.arrayBufferToBase64(res);

              
              if(typeOfDocument === 'application/pdf') {

                this.typeActualDocument = 'PDF'
                this.imagesElaborations[this.dataFromDocumentClassification.elaborations[elaboration].elabId] = base64CompletePreview;

              } else {

                this.typeActualDocument = 'Image'
                this.imagesElaborations[this.dataFromDocumentClassification.elaborations[elaboration].elabId] = base64CompletePreview;
                
              }


            }, (err) => {
              console.log("Errore")
            }
          );


        }, (err) => {
          console.log("err")
        }
      );

    }


  }


  public visualizeBase64(imageString) {
    if(imageString) {
      return "data:image/jpg;base64,"+imageString;
    }
    
  }




  goToReprocessDocument() {

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseRelaborate = this.documentService.relaborateDocument(sessionStorage.getItem('sessionId'),this.dataFromDocumentClassification.docId);
    responseRelaborate.subscribe(
      res => {

        // Elimina lo spinner se la risposta è andata bene
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

            // Visualizza lo spinner
          this.element.className = 'blur'
          this.loading.classList.remove('displayLoading');

        this.pollingElaboration(res.sessionId, res.docId)



      }, (error) => {
        console.log("Errore in fase di rielaborazione")
      }
    )
  }

  async pollingElaboration(sessionId, docId) {
    let responseElaborations = await this.elaborationsService.getElaborations(sessionId, docId);

    responseElaborations.subscribe(
      res => {
        let pending = false;

        for(const key in res) {
          if(res[key].elaborationState == "PENDING") { // Se c'è un oggetto pending
            pending = true;
            break;
          }
        }

        if(pending) {
          setTimeout(()=>{                         
            this.pollingElaboration(sessionId, docId);
          }, 3000);
        } else {

          // Elimina lo spinner quando finisce il polling
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');

          this.dataFromDocumentClassification.elaborations = res;
          this.createPreviewImages(sessionId);
          this.doLocalCrossCheck();


        }

      }, (error) => {
        console.log("Errore: ", error)
      }
    );
  }


  // Metodo di utilità che dato un ArrayBuffer restituisce l'equivalente stringa in base64
  private arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  private dataURLtoFile(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "File name", {type:mime});
  }


}