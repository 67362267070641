import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../api/api/statistics.service';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

import { ngxCsv } from 'ngx-csv/ngx-csv';



@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  // Servono per lo spinner
  element;
  loading;

  // Modello
  modelData;
  precisionCardsModello;
  reliabilityGreaterThanThresholdObjectsModello;
  selectedThresholdModello;
  actualCardReliabilityModello;
  sideCodes = {};

  // Estrazione
  estractionData
  precisionCardsEstrazione;
  correctnessCardsEstrazione;
  reliabilityGreaterThanThresholdObjectsEstrazione;
  selectedThresholdAffidabilitaEstrazione;
  actualCardReliabilityEstrazione;

  similarity;
  selectedThresholdSimilaritaEstrazione;
  actualCardSimilarityEstrazione;

  constructor(private statisticsService: StatisticsService) {
    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');
  }

  async ngOnInit() {

    this.fromSideCodeToSideName();

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseGetAiModel = await this.statisticsService.getAiModel();
    responseGetAiModel.subscribe(
      async res => {
        console.log("RES getAiModel: ", res)
        this.modelData = res;
        this.precisionCardsModello =  JSON.parse(JSON.stringify(res['precision']));

        this.actualCardReliabilityModello = JSON.parse(JSON.stringify(res['reliabilityGreaterThanThreshold'][0].documentTypes));
        this.reliabilityGreaterThanThresholdObjectsModello = JSON.parse(JSON.stringify(res['reliabilityGreaterThanThreshold']));


        let responseReturnedStatistics = await this.statisticsService.getReturnedStatistics();
        
        responseReturnedStatistics.subscribe(
          res => {

            this.estractionData = res;

            console.log("RES responseReturnedStatistics: ", res)

            // Elimina lo spinner
            this.element.classList.remove('blur');
            this.loading.classList.add('displayLoading');
              
            this.precisionCardsEstrazione =  JSON.parse(JSON.stringify(res['precision']));
            this.correctnessCardsEstrazione =  JSON.parse(JSON.stringify(res['correctness']));

            this.actualCardReliabilityEstrazione = JSON.parse(JSON.stringify(res['reliabilityGreaterThanThreshold'][0].documentTypes));
            this.reliabilityGreaterThanThresholdObjectsEstrazione = JSON.parse(JSON.stringify(res['reliabilityGreaterThanThreshold']));

            this.actualCardSimilarityEstrazione = JSON.parse(JSON.stringify(res['similarity'][0].documentTypes));
            this.similarity = JSON.parse(JSON.stringify(res['similarity']));
    
          }, (error) => {
            console.log("ERROR: ", error)
          }
        );
        
      }, (error) => {
        console.log("ERROR: ", error)
      }
    );
  }

  public fromSideCodeToSideName() {
    
    let documentsTypes = JSON.parse(sessionStorage.getItem("documents"));

    for(let documentType of documentsTypes) {
      let parts = documentType.parts;

      for(let part in parts) {
        this.sideCodes[parts[part].name] = parts[part].externalDescription;
      }

    }

  }

  public createTitle(englishTitle) {
    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    for(const key in documentsTitles) {
      if(documentsTitles[key].documentTypeId == englishTitle) {
        return documentsTitles[key].externalDescription;
      }
    }
  }

  public returnToward(toward) {

    return this.sideCodes[toward]
  }

  

  modifyDataForSpecificThresholdModello() {
    for(let i=0; i< this.reliabilityGreaterThanThresholdObjectsModello.length; i++) {
      if(this.reliabilityGreaterThanThresholdObjectsModello[i].threshold == this.selectedThresholdModello) {
        this.actualCardReliabilityModello = this.reliabilityGreaterThanThresholdObjectsModello[i].documentTypes;
        break;
      }
    }
  }

  modifyDataForSpecificThresholdAffidabilitaEstrazione() {
    for(let i=0; i< this.reliabilityGreaterThanThresholdObjectsEstrazione.length; i++) {
      if(this.reliabilityGreaterThanThresholdObjectsEstrazione[i].threshold == this.selectedThresholdAffidabilitaEstrazione) {
        this.actualCardReliabilityEstrazione = this.reliabilityGreaterThanThresholdObjectsEstrazione[i].documentTypes;
        break;
      }
    }
  }

  modifyDataForSpecificThresholdSimilaritaEstrazione() {
    for(let i=0; i< this.similarity.length; i++) {
      if(this.similarity[i].threshold == this.selectedThresholdSimilaritaEstrazione) {
        this.actualCardSimilarityEstrazione = this.similarity[i].documentTypes;
        break;
      }
    }
  }

  percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  roundPercentage(number) {
    if(!number) {
      return "0%";
    }
    let percentage = Math.trunc(number*100)/100 + "%";
    return percentage;
  }


  dataModelloCSV = [];

  assemblyDataEstrazioneCorrettezza() {
    
    for(let data in this.estractionData['correctness']) {

      let singleObject = {};

      singleObject['Documento'] = this.createTitle(this.estractionData['correctness'][data].documentType);
    
      singleObject['% Doc con almeno 1 estrazione corretta'] = this.roundPercentage(this.percentage(this.estractionData['correctness'][data].partialNumber, this.estractionData['correctness'][data].totalNumber));

      if(singleObject['Documento']) {
        this.dataModelloCSV.push(singleObject); // TODO: dopo la creazione del .csv ricordarsi di svuotare this.dataModelloCSV o scaricherà CSV sempre più grandi
      }

    }
    

  }

  assemblyDataEstrazionePrecisione() {

    for(let data in this.estractionData['precision']) {

      let singleObject = {};

      singleObject['Documento'] = this.createTitle(this.estractionData['precision'][data].documentType);
    
      singleObject['% Entità estratte correttamente'] = this.roundPercentage(this.percentage(this.estractionData['precision'][data].partialNumber, this.estractionData['precision'][data].totalNumber));

      if(singleObject['Documento']) {
        this.dataModelloCSV.push(singleObject); // TODO: dopo la creazione del .csv ricordarsi di svuotare this.dataModelloCSV o scaricherà CSV sempre più grandi
      }
    }

  }

  assemblyDataModelloPrecisione() {    

    for(let data in this.modelData) {
      
      for(let obj of this.modelData[data]) {

        if(!('threshold' in obj)){
          for(let part of obj.parts) {

            let singleObject = {};
  
            singleObject['Documento'] = this.createTitle(obj.documentTypeId) + " - " + this.returnToward(part.code);
    
            singleObject['% Doc Correttamente Riconosciuti'] = this.roundPercentage(this.percentage(part.notForcedNumber, part.totalNumber));
  
            this.dataModelloCSV.push(singleObject); // TODO: dopo la creazione del .csv ricordarsi di svuotare this.dataModelloCSV o scaricherà CSV sempre più grandi
          }
        }
        
      }
    
    }
      
  }

  assemblyDataEstrazioneSimilarita() {
    // Ottieni array di threshold
    let thresholdsArray = [];

    for(let data in this.estractionData['similarity']) {
      thresholdsArray.push(this.estractionData['similarity'][data].threshold)
    }

    // Ottieni array di documentsType (serve per le chiavi)
    let documentsArray = this.estractionData['similarity'][0].documentTypes;


    // Cicla su documentsArray
    for(let document of documentsArray) {
      let singleObject = {};

      singleObject['Documento'] = this.createTitle(document.documentType);

      for(let threshold of thresholdsArray) {
        for(let obj of this.estractionData['similarity']) {
          if(obj.threshold === threshold) {
            for(let nameDocument of obj.documentTypes) {
              if(nameDocument.documentType === document.documentType) {
                singleObject['% di similarità sul totale di elementi restituiti con confidenza > ' + threshold] = this.roundPercentage(this.percentage(nameDocument.partialNumber, nameDocument.totalNumber));
              }
            }
          }
        }
      }

      if(singleObject['Documento']) {
        this.dataModelloCSV.push(singleObject); // TODO: dopo la creazione del .csv ricordarsi di svuotare this.dataModelloCSV o scaricherà CSV sempre più grandi
      }
    }
  }

  assemblyDataEstrazioneAffidabilita() {
    // Ottieni array di threshold
    let thresholdsArray = [];

    for(let data in this.estractionData['reliabilityGreaterThanThreshold']) {
      thresholdsArray.push(this.estractionData['reliabilityGreaterThanThreshold'][data].threshold)
    }

    // Ottieni array di documentsType (serve per le chiavi)
    let documentsArray = this.estractionData['reliabilityGreaterThanThreshold'][0].documentTypes;


    // Cicla su documentsArray
    for(let document of documentsArray) {
      let singleObject = {};

      singleObject['Documento'] = this.createTitle(document.documentType);

      for(let threshold of thresholdsArray) {
        for(let obj of this.estractionData['reliabilityGreaterThanThreshold']) {
          if(obj.threshold === threshold) {
            for(let nameDocument of obj.documentTypes) {
              if(nameDocument.documentType === document.documentType) {
                singleObject['% Entità riconosciute con confidenza > ' + threshold] = this.roundPercentage(this.percentage(nameDocument.partialNumber, nameDocument.totalNumber));
              }
            }
          }
        }
      }

      this.dataModelloCSV.push(singleObject); // TODO: dopo la creazione del .csv ricordarsi di svuotare this.dataModelloCSV o scaricherà CSV sempre più grandi

    }


  }

  assemblyDataModelloAffidabilita() {

    // Ottieni array di threshold
    let thresholdsArray = [];
    
    for(let data in this.modelData['reliabilityGreaterThanThreshold']) {
      thresholdsArray.push(this.modelData['reliabilityGreaterThanThreshold'][data].threshold)
    }

    // Ottieni array di documentsType (serve per le chiavi)
    let documentsArray = this.modelData['reliabilityGreaterThanThreshold'][0].documentTypes;

    // Cicla su documentsArray
    for(let document of documentsArray) {

      for(let part of document.parts) {

        let singleObject = {};

        singleObject['Documento'] = this.createTitle(document.documentTypeId) + " - " + this.returnToward(part.code);

        for(let threshold of thresholdsArray) {

          for(let obj of this.modelData['reliabilityGreaterThanThreshold']) {
            if(obj.threshold === threshold) {
              
              for(let nameDocument of obj.documentTypes) {

                if(nameDocument.documentTypeId === document.documentTypeId) {
                  
                  for(let namePart of nameDocument.parts) {

                    if(namePart.code === part.code) {
                      singleObject['% Doc Correttamente Riconosciuti con confidenza > ' + threshold] = this.roundPercentage(this.percentage(namePart.notForcedNumber, namePart.totalNumber));
                    }
                  }

                }
              }
            }
            
          }

        }

        this.dataModelloCSV.push(singleObject); // TODO: dopo la creazione del .csv ricordarsi di svuotare this.dataModelloCSV o scaricherà CSV sempre più grandi

      }

    }


    



  }

  // ************************ Download Modello ************************
  fileDownloadModelloPrecisione() {

    this.assemblyDataModelloPrecisione();

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Report Data',
      useBom: true,
      headers: ["Documento", "% Doc Correttamente Riconosciuti"]
    };
   
    new ngxCsv(this.dataModelloCSV, "Report", options);

    this.dataModelloCSV = [];

  }

  fileDownloadModelloAffidabilita() {

    this.assemblyDataModelloAffidabilita();

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Report Data',
      useBom: true,
      headers: ["Documento", "% Doc Correttamente Riconosciuti con confidenza > 0.65", "% Doc Correttamente Riconosciuti con confidenza > 0.75"]
    };
   
    new ngxCsv(this.dataModelloCSV, "Report", options);

    this.dataModelloCSV = [];

  }


  // ************************ Download Estrazione ************************

  fileDownloadEstrazioneSimilarita() {
    this.assemblyDataEstrazioneSimilarita();

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Report Data',
      useBom: true,
      headers: ["Documento", "% di similarità sul totale di elementi restituiti con confidenza > 0.85", "% di similarità sul totale di elementi restituiti con confidenza > 0.9"]
    };
   
    new ngxCsv(this.dataModelloCSV, "Similarity_Report", options);

    this.dataModelloCSV = [];
  }

  // % di similarità sul totale di elementi restituiti con confidenza > 

  fileDownloadEstrazioneAffidabilita() {
    this.assemblyDataEstrazioneAffidabilita();

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Report Data',
      useBom: true,
      headers: ["Documento", "% Entità riconosciute con confidenza > 0.6", "% Entità riconosciute con confidenza > 0.8"]
    };
   
    new ngxCsv(this.dataModelloCSV, "Reliability_Report", options);

    this.dataModelloCSV = [];

  }

  

  fileDownloadEstrazionePrecisione() {

    this.assemblyDataEstrazionePrecisione();

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Report Data',
      useBom: true,
      headers: ["Documento", "% Entità estratte correttamente"]
    };
   
    new ngxCsv(this.dataModelloCSV, "Precision_Report", options);

    this.dataModelloCSV = [];

  }

  fileDownloadEstrazioneCorrettezza() {

    this.assemblyDataEstrazioneCorrettezza();

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Report Data',
      useBom: true,
      headers: ["Documento", "% Doc con almeno 1 estrazione corretta"]
    };
   
    new ngxCsv(this.dataModelloCSV, "Correctness_Report", options);

    this.dataModelloCSV = [];

  }

}
