import { Component } from '@angular/core';
import { SessionsService } from './api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WebAppBluescan';

  constructor(){}

  ngOnInit() {

    sessionStorage.setItem("originalSize", JSON.stringify(false));

  
  }
}
