<div class="resizable-draggable documentExtraction"
[style.transform]="'translate3d(' + left + 'px,' + top + 'px,' + '0px)'">


    <div class="card-header documentExtraction">

        <div class="row">
            <div class="col-md-8 titleSideDocument">
                {{documentTitle | uppercase}} - {{toward | uppercase}}
            </div>

            <div class="col-md-4">
                <button [disabled]="!elaborationDone" (click)="goToOCRResultPage()" class="action-button goToDetail">
                    DETTAGLIO <em class="fa fa-arrow-right"></em>
                </button>
            </div>
        </div>

    

    </div>


    <div class="card-body">
    
        <div class="row" style="margin-left: 1%;">
            <strong>CLASSIFICATION</strong>
        </div>

        <div class="row">
            <div class="col-md-8">

                <div *ngFor="let label of predictedLabels">
 

                    <section class="progressBar"> <!-- TODO: implementare funzioncina che cambia il colore della barra a seconda della percentuale-->
                        <mat-progress-bar 
                            [appProgressBarColor]="'#28a745'"
                            [mode]="mode"
                            value="{{decimalToPercent(label.score)}}"
                            [bufferValue]="decimalToPercent(label.score)">
                        </mat-progress-bar>
                        <div style="position: absolute; padding: 10px; color: white;">{{label.labelName}}</div>
                        <div style="position: relative; color: black; right: 10%;"><strong>{{decimalToPercent(label.score)}}%</strong></div>
                    </section>


                </div>

            </div>
            <div class="col-md-4" *ngIf="objectElaborations.photo.type !== 'application/pdf'">
                <img alt="Immagine del documento" class="document-image imageDocument" [src]="url">
            </div>
            <div class="col-md-4" *ngIf="objectElaborations.photo.type === 'application/pdf' && url">
                <ng2-pdfjs-viewer [pdfSrc]="dataURLtoFile(url)" [page]="1"></ng2-pdfjs-viewer>
            </div>
        </div>

        <div class="row" style="margin-left: 1%;">
            <strong>ELABORATIONS</strong>
        </div>


        <div class="row">
            <div class="col-md-8">

                <div *ngFor="let elaboration of objectElaborations.elaborations">

                

                    <div class="row">
                        <div class="col-md-2">
                            {{elaboration.elabId}}
                        </div>
                        <div class="col-md-7">
                            {{elaboration.filterType}}
                        </div>
                        <!-- TODO: a che serve la percentuale?
                        <div class="col-md-3">
                            85%
                        </div>
                        -->
                        <div class="col-md-3">
                            <em class="fa {{getIconFromElaborationStatus(elaboration.elaborationState)}}"></em> <!--  -->
                        </div>
                    </div>


                </div>

            

            </div>

            <div class="col-md-4">
                <!--
                <button class="action-button file-download-log">
                    SCARICA LOG
                </button>
                -->
            </div>
        </div>


        

</div>




    
</div>
