import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insert-side-document',
  templateUrl: './insert-side-document.component.html',
  styleUrls: ['./insert-side-document.component.scss']
})
export class InsertSideDocumentComponent implements OnInit {

  image: string = "assets/images/noImage.jpg";

  imageError: string = "assets/images/error.png"

  imageSuccess: string = "assets/images/success.png"


	url: any; // Serve per visualizzare nel template l'immagine

  imageFile : File; // Serve per avere metadati sull'immagine (nome e size)

  success: boolean; // è a true se il caricamento ha avuto successo

  modeProgressBar: string = 'determinate'; // Indica lo stato della progress bar
  valueProgressBar: number = 0; // Indica la percentuale della progress bar



  // @Input: nome del documento e verso del documento (es: Patente, Fronte)

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
  }



  public selectFile(event: any) {
		if(!event.target.files[0] || event.target.files[0].length == 0) {
			return;
		}
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

    this.imageFile = event.target.files[0];
		
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
		reader.onload = (_event) => {
			this.url = reader.result; 
		}

    
	}

  // Funzione che data una size in byte la converte nell'unità più "friendly"
  public formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';
 
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  // Invocata quando l'utente decide di caricare la foto sul sistema
  public startLoadingPhoto() {

    this.modeProgressBar = 'indeterminate';

    // TODO: Chiamata al servizio 1) Upload di una foto. Al momento il timeout simula la chiamata asincrona
    setTimeout(() => {
      this.modeProgressBar = 'determinate'; // La Progress BAR è completa
      this.valueProgressBar = 100;
    }, 3000);

  }

  // Invocata quando l'utente decide di cancellare la foto dopo un upload avvenuto con successo/warning
  public deleteUploadedPhoto() {
    this.backToInitialState();
  }

  // Invocata quando l'utente decide di annullare l'upload della foto
  public removeUploadedPhoto() {
    this.backToInitialState();    
  }

  // Funzione di utilità che ripristina lo stato iniziale
  private backToInitialState() {
    this.modeProgressBar = 'determinate'; // La Progress BAR è completa
    this.valueProgressBar = 0;
    this.url = undefined;
  }
 
}
 