<h1>Elaborazione documenti</h1>

<!-- Riga iniziale: sempre presente -->
<div class="row">

    <div *ngIf="!elaborationDone" class="col-md-6">
            <app-document-extraction
                [left]="10" 
                [top]="10"

                [firstCard]="true"
                
                (eventFinishElaborations)="arrivedData($event)"

                (eventDeletedElaborations)="deleteData($event)"

                [deleteAll]="deleteAll"

                [documentsExternal]="documentsRemained"
            ></app-document-extraction>
    </div>


    <!-- Se this.objectElaborations NON ha almeno una entity -->
    <div *ngIf="isEmpty(this.objectElaborations)" class="descriptionInstructions">
        <span>
            Seleziona un tipo di documento dal menu a tendina e premi sui pulsanti
            <button class="action-button fake-file-upload">
                <em class="fa fa-plus"></em> 
            </button><br>
            per acquisire ogni parte dello stesso.<br><br>
            Per confermare i documenti caricati premere il pulsante <button class="action-button fake-terminate-procedure">Termina procedura</button>.<br><br>
            Per sottomettere un nuovo documento clicca su <button class="action-button fake-aggiungi-documento">Aggiungi documento</button>.
        </span>

    </div>

    
    <div class="col-md-6">

        <span *ngFor="let entity of this.objectElaborations | keyvalue">

            <span *ngIf="entity && entity.value && entity.value.docId && showDocumentClassification[entity.value.docId]">
                <app-document-classification
                    [left]="10" 
                    [top]="10"
                    [objectElaborations]="entity.value"
                    [toward]="entity.value.toward"
                    [elaborationDone]="elaborationDone"
                ></app-document-classification>
            </span>  

            <br>
            
        </span>

              

    </div>
    
</div>

<!-- Righe per il resto delle cards -->
<span *ngIf="!removeUniqueOtherCard()">
    <div class="row" *ngFor="let item of [].constructor(totalNewCards); let i = index">


        <div class="col-md-6" *ngIf="!elaborationDone">
            <br>

                <app-document-extraction
                [left]="10" 
                [top]="10"
                
                (eventFinishElaborations)="arrivedOtherData($event, i)"
                (eventDeletedElaborations)="deleteOtherData($event, i)"

                (eventNoMoreAppear)="reduceNumberOfCards($event)"

                [documentsExternal]="documentsRemained"

                ></app-document-extraction>

        </div>

        <div class="col-md-6" *ngIf="this.objectOtherElaborations[i]">

            <span *ngFor="let entity of Object.keys(this.objectOtherElaborations[i])">

                <span *ngFor="let entity2 of Object.keys(this.objectOtherElaborations[i][entity])">

                    <span *ngIf="this.objectOtherElaborations && this.objectOtherElaborations[i] && this.objectOtherElaborations[i][entity] &&  this.objectOtherElaborations[i][entity][entity2] && this.objectOtherElaborations[i][entity][entity2].toward">

                    <app-document-classification
                    [left]="10" 
                    [top]="10"
                    [objectElaborations]="this.objectOtherElaborations[i][entity][entity2]"
                    [toward]="this.objectOtherElaborations[i][entity][entity2].toward"
                    [elaborationDone]="elaborationDone"

                    

                    ></app-document-classification>

                    </span>


                </span>
        
                <br>
                
            </span>

                    

        </div>


    </div>
</span>

<!--BOTTONI A FONDO PAGINA -->
<div class="center buttonAtEndOfPage" *ngIf="!elaborationDone">
    <div class="row">
        <div class="col-md-12">
            <button [disabled]="allShowDocumentClassificationAreFalse() || disableAddDocument || documentsRemained.length === 0" class="action-button add-document" (click)="addDocumentToHomepage()">Aggiungi documento</button>   
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-md-12">
            <button [disabled]="allShowDocumentClassificationAreFalse() && isEmptyObjectOtherElaborations()" (click)="terminateProcedure()" class="action-button terminate-procedure">Termina procedura</button>
        </div>
    </div>


</div>



<div class="row center" *ngIf="elaborationDone">
	<div class="col-md-12">
        <button class="action-button buttonAfterOcr" routerLink="/globalCrossCheck"><strong>RISULTATO FINALE</strong></button>
    </div>

    <div class="col-md-12">
        <button class="action-button buttonEndElaboration" (click)="endOfElaboration()"><strong>Fine Elaborazione</strong></button>
    </div>
</div>
