import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FORMERR } from 'dns';
import { compileFunction } from 'vm';
import { LogsService, PostLogsRequest } from '../api';


export interface Log {
  eventId: string;
  logGroupName: string,
  logStreamName: string,
  timestamp: string,
  message: string
}

var ELEMENT_DATA: Log[] = [];


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  events = [];

  nextTokenPresent = {}; // Dizionario 

  nextGroups = {}; // Dizionario che ha come chiave il nome di un logGroup e come valore il token per recuperare le restanti pagine

  // Servono per lo spinner
  element;
  loading;

  toppings = new FormControl();

  limits = new FormControl();

  formGroupLogGroups = new FormControl();

  displayedColumns: string[] = ['eventId', 'logGroupName', 'logStreamName', 'timestamp', 'message'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  logGroups = []; // Array che elenca ogni oggetto logGroup (viene popolato all'inizio)
  logGroupsNames: string[] = []; // Array che elenca le stringhe di nomi dei logGroup (viene popolato all'inizio)
  limitValues: string[] = ["Nessuno", "10", "25", "50", "100"];  // Array che regola i limit consentiti

  // Parametri di query
  q = ""; // Query dell'utente per cercare i logs
  logGroupsQuery;
  startQuery;
  endQuery;

  // Parametri "grezzi" forniti dall'utente in input
  selectedLogGroups;
  selectedDataInizio;
  selectedDataFine;
  selectedLimit;

  constructor(
              private logsService: LogsService) {
    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');
  }

  async ngOnInit() {



    let responseLogGroups = await this.logsService.getLogGroups();

    //let responseLogGroups = await this.logService.logGroups();
    responseLogGroups.subscribe(
      res => {
        this.logGroups = res['logGroups'];
        for(let logGroup of this.logGroups) {
          this.logGroupsNames.push(logGroup.name)
        }
      }, (error) => {
        console.log("ERRORE RECUPERO LOG-GROUPS")
      }
    )

  }

  async searchLogs() {

    // Per prima cosa, se c'è l'array this.selectedLogGroups riempito, va formattato come giusta stringa da dare come queryParam
    if(this.selectedLogGroups) {
      this.logGroupsQuery = [];

      let arrayStringsLogGroups = this.selectedLogGroups.toString().split(",");

      for(const logGroup of arrayStringsLogGroups) {

        let objLogGroup = {
          "name" : logGroup
        };
        this.logGroupsQuery.push(objLogGroup);
      }
    }

    if(this.selectedDataInizio) {
      this.startQuery = this.selectedDataInizio.getTime();
    }

    if(this.selectedDataFine) {
      this.endQuery = this.selectedDataFine.getTime();
    }

    this.events = [];

    this.callForLog(true);
  }

  async moreLogs() {

    for(let logGroup of this.logGroupsQuery) {
      for(let key in this.nextGroups) {
        if(key === logGroup.name) {
          logGroup.nextToken = this.nextGroups[key]; // Metti il token nel giusto logGroup
        }
      }
    }
    this.events = [];

    this.callForLog(false);
  }

  // Questa funzione effettua la chiamata HTTP
  async callForLog(firstTime: boolean) {
    let body : PostLogsRequest = {}

    if(this.q) {
      body['query'] = this.q;
    }

    if(this.startQuery) {
      body['startDate'] = this.startQuery;
    }

    if(this.endQuery) {
      body['endDate'] = this.endQuery;
    }

    if(this.logGroupsQuery) {
      body['logGroups'] = this.logGroupsQuery;
    }

    if(this.selectedLimit && this.selectedLimit !== 'Nessuno') {
      body['limit'] = this.selectedLimit;
    }

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseLog = await this.logsService.postLogs(body);

    responseLog.subscribe(
      res => {

        if(firstTime) {
          this.logGroupsQuery = res.logGroups;
        }
        

        console.log("RESPONSE: ", res)

        // Elimina lo spinner se la risposta è andata bene
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

        for(const logGroupEvents of res.logGroups) {

          let logGroupName = logGroupEvents.name;

          let nextTokenPresent;

          // TODO: Se nell'oggetto esiste 'nextToken', deve apparire il pulsante e nextToken va salvato in una struttura apposita.
          if('nextToken' in logGroupEvents) {
            nextTokenPresent = true;
            this.nextTokenPresent[logGroupName] = true;

            // Devo salvare un log
            this.nextGroups[logGroupName] = logGroupEvents.nextToken
          } else {
            //this.nextGroups[logGroupName] = null;
            nextTokenPresent = false;
            this.nextTokenPresent[logGroupName] = false;
          }

          // Crea oggetto gemello di logGroupEvents.events
          let eventsArray;
          if(logGroupEvents.events) {
            eventsArray = JSON.parse(JSON.stringify(logGroupEvents.events));
            // Per ogni elemento di questo array
            for(const element of eventsArray) {
              element['logGroupName'] = logGroupName;
            }

            this.events = this.events.concat(eventsArray);
          }
          
        }

        if(this.tokenPresent() || firstTime) {
          this.dataSource = <any>this.events;//<any>res.logGroups[0].events; // TODO: il datasource deve contenere anche il logGroupName
        }

      }, (error) => {
        // Elimina lo spinner se la risposta è andata male
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

        console.log("ERRORE LOG: ", error);
      }
    );
  }

  tokenPresent() {
    for(let key in this.nextTokenPresent) {
      if(this.nextTokenPresent[key] === true) {
        return true; // Resituisco false perchè c'è ancora un token
      }
    }
    return false;
  }

  

}