<h1>Cross Check Result</h1>

<div *ngIf="isDataLoaded">

<div class="container containerCrossCheck">

   <div *ngFor="let docsCrossCheck of docsCrossCheckList; let i = index">

      <div class="card mt-3 col-10 offset-1 col-md-12 offset-md-1">
         <div class="bold card-header">{{titles[i]}} - <span class="bold" [ngClass]="{'green': docsCrossCheck.documentEntities.confidenceIndex >= 0.80, 'red' : docsCrossCheck.documentEntities.confidenceIndex < 0.80 }"> {{docsCrossCheck.documentEntities.confidenceIndex}}</span></div>
         <div class="card-body row">
            <div class="col-6">


               <div *ngFor="let entity of docsCrossCheck.documentEntities.entities">
                  <p><span class="bold">{{entity.key}}</span>: {{entity.value}} - <span [ngClass]="{'green': entity.confidence >= 0.80, 'red' : entity.confidence < 0.80 }">{{entity.confidence}}</span></p>
                  <p></p>
               </div>



            </div>

         </div>
      </div>
   </div>


    <div id="cross-check-container" class="card mt-3 col-10 offset-1 col-md-12 offset-md-1 sticky-top align-self-start">
       <div id="global-cross-check" class="card border-success">
          <div class="card-header bg-transparent border-success"><span class="bold">Global Cross Check - </span><span class="bold" [ngClass]="{'green': globalCrossCheck.confidenceIndex >= 0.80, 'red' : globalCrossCheck.confidenceIndex < 0.80 }">{{globalCrossCheck.confidenceIndex}}</span></div>
          <div class="card-body global-body">
             <div *ngFor="let entity of globalCrossCheck.entities">
               <p><span class="bold">{{entity.key}}</span>: {{entity.value}} - <span [ngClass]="{'green': entity.confidence >= 0.80, 'red' : entity.confidence < 0.80 }">{{entity.confidence}}</span></p>
            </div>
          </div>
       </div>
    </div>
 </div>




 <div class="row center">
	<div class="col-md-12">
		<div class="centerButton"><button  class="action-button buttonAfterOcr" routerLink="/home"><strong>Torna all'elaborazione</strong></button></div>
	</div>
	
   </div>



</div>