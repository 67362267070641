import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ElaborationsService } from '../api';

@Component({
  selector: 'app-home-relaboration',
  templateUrl: './home-relaboration.component.html',
  styleUrls: ['./home-relaboration.component.scss']
})
export class HomeRelaborationComponent implements OnInit {

  dataFromOcrResult;

  modeProgressBar = 'determinate'

  objectElaborations = {};

  showCard = false;

  constructor(private router: Router,
              private elaborationsService: ElaborationsService) {

    this.dataFromOcrResult =  this.router.getCurrentNavigation().extras.state;

  }

  ngOnInit(): void {
  }


  async pollingElaboration() {

    this.modeProgressBar = 'indeterminate'


    let responseElaborations = await this.elaborationsService.getElaborations(this.dataFromOcrResult.sessionId, this.dataFromOcrResult.docId)

    responseElaborations.subscribe(
      res => {
        let pending = false;

        for(const key in res) {

          if(res[key].elaborationState == "PENDING") { // Se c'è un oggetto pending
            pending = true;
            break;
          }
        }

        if(pending) {
          this.pollingElaboration();
        } else {
          this.modeProgressBar = 'determinate';

          this.objectElaborations["docId"] = this.dataFromOcrResult.docId;
          this.objectElaborations["photo"] = "PHOTO"; // Da mettere
          this.objectElaborations["elaborations"] = res;
          this.objectElaborations["title"] = "TITLE" // Da mettere
          this.objectElaborations["toward"] = "Fronte" // Da mettere

          this.showCard = true;



        }




      }, (error) => {
        console.log("Errore: ", error)
      }
    )





  }

}
