import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CrossCheckService, DocumentsService, ElaborationsService, ServicesService, SessionsService } from '../api';

@Component({
  selector: 'app-ocr-result2',
  templateUrl: './ocr-result2.component.html',
  styleUrls: ['./ocr-result2.component.scss']
})
export class OcrResult2Component implements OnInit {

  // Servono per lo spinner
  element;
  loading;

  CompletedElaboration = false;

  dataOld;

  oldDocuments = [];
  oldElaborations;


  patenteFronte: string = "assets/images/PATENTE_FRONTE.jpg";

  patenteNegativo: string = "assets/images/PATENTE_NEGATIVO.jpg";

  result: string = "assets/images/result.jpg";

  imageSuccess: string = "assets/images/success.png"

  imageError: string = "assets/images/error.png"


  constructor(private router: Router,
              private elaborationsService: ElaborationsService,
              private documentsService: DocumentsService,
              private crossCheckService: CrossCheckService,
              private documentService: DocumentsService,
              private sessionsService: SessionsService,
              private servicesService: ServicesService,
              
              private _sanitizer: DomSanitizer) {


    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');

    this.dataOld =  this.router.getCurrentNavigation().extras.state;

    if(!this.dataOld) {
      this.dataOld = JSON.parse(sessionStorage.getItem("oldElaboration"))
    }   

  }

  ngOnInit(): void {

    this.getOldDocuments();
    this.CompletedElaboration = true;

  }

  docsCrossCheck;
  globalCrossCheck;


  async doGlobalCrossCheck(sessionIdEncoded) {
      
    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');
  
      let responseGlobalCrossCheck = await this.crossCheckService.getGlobalCrossCheck(sessionIdEncoded);
      responseGlobalCrossCheck.subscribe(
        res => {

          console.log("RES__: ", res)
  
          // Elimina lo spinner se la risposta è andata bene
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');
  

          this.docsCrossCheck = res["docsCrossCheck"]; // TODO: è giusto prendere il primo elemento sempre? Perchè è un arra
          this.globalCrossCheck = res["globalCrossCheck"];
  
        }, (error) => {
          // Elimina lo spinner precedente
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');
          console.log("ERRORE GLOBAL CROSSCHECK: ", error)
        }
      )
  }



























  public async getOldDocuments() {


    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseDocuments = await this.documentsService.getDocuments(this.dataOld.sessionIdEncoded);
    responseDocuments.subscribe(
      async res => {

        if(res.length === 0) {
          // Elimina lo spinner precedente
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');
        }

        if(res.constructor.name == "Array") {
          this.oldDocuments = res;

          // Ottieni le elaborazioni
          for(let i=0; i<this.oldDocuments.length; i++) {
            
            this.setOldElaborations(i, this.oldDocuments[i].docId);

            
          }

        } else {
          // Elimina lo spinner precedente
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');
        }

        // *****1° alternativa *****
        console.log("this.oldDocuments: ", this.oldDocuments)
        
        var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

        for(let document of this.oldDocuments) {

          for(let label of document.predictedLabels) {


            for(let documentTitle of documentsTitles) {

              var labelFields = label.labelName.split('_');

              if(documentTitle.documentTypeId.startsWith(labelFields[0])){

                
                document['externalFoundLabel'] = documentTitle.externalDescription;
              
              
              }

            }

            

          }





        }

        

        
      }, (error) => {
        console.log("ERRORE VECCHI DOCUMENTI: ", error)
      }
    )
  }


  
  public async setOldElaborations(index, docId) {

    // Elimina lo spinner precedente
    this.element.classList.remove('blur');
    this.loading.classList.add('displayLoading');


    
    let responseElaborations = await this.elaborationsService.getElaborations(this.dataOld.sessionIdEncoded,docId);

    responseElaborations.subscribe(
      res => {

        this.oldDocuments[index]['elaborations'] = res;

        this.createPreviewImages(this.dataOld.sessionIdEncoded, index, res)

        if(this.dataOld.state == 'CLOSED' || this.dataOld.state == 'COMPLETED') {
          this.doLocalCrossCheck(index, docId);
        } else {
          // Elimina lo spinner precedente
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');
        }
        
        


      }, (err) => {
        console.log("Errore responseElaborations:", err)
      }
    )
    


  }


  examplePdf="assets/images/example.pdf"



  private async createPreviewImages(sessionId, index, elaborations) {


    for(const elaboration in elaborations) {

      let typeOfDocument = "";

      // Recupera le informazioni sull'estensione del documento
      let responseGetDocument = await this.documentService.getDocument(sessionId, elaborations[elaboration].docId);
      responseGetDocument.subscribe(
        res => {
          
          typeOfDocument = res.extension === 'pdf' ? 'application/pdf' : 'image/'+res.extension

          let responsePreview = this.elaborationsService.getContent(sessionId, elaborations[elaboration].docId, elaborations[elaboration].elabId, typeOfDocument, sessionStorage.getItem("originalSize") === 'true' ? true : false);
          responsePreview.subscribe(
            res => {
              console.log("RES RES: ", res);

              let base64CompletePreview = "data:"+typeOfDocument+";base64,"+this.arrayBufferToBase64(res);

              
              if(typeOfDocument === 'application/pdf') {

                this.oldDocuments[index]['elaborations'][elaboration]['pdf'] =  this.dataURLtoFile(base64CompletePreview);

              } else {
                
                this.oldDocuments[index]['elaborations'][elaboration]['image'] = base64CompletePreview;
              }

              

    
              
    
            }, (error) => {
              console.log("ERR ERR: ", error);
            }
          )
    





        }, (error) => {
          console.log("ERRORE CHIAMATA PREVIEW: ", error)
        }
      );


      /*
      let responsePreview = this.elaborationsService.getElaborationPreview(this.dataOld.sessionIdEncoded, elaborations[elaboration].docId, elaborations[elaboration].elabId)
      responsePreview.subscribe(
        res => {

          this.oldDocuments[index]['elaborations'][elaboration]['image'] = res['documentPreview']

        }, (error) => {
          console.log("ERRORE CHIAMATA PREVIEW: ", error)
        }
      )
      */

    }

  }

  // Metodo di utilità che dato un ArrayBuffer restituisce l'equivalente stringa in base64
  private arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  private dataURLtoFile(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "File name", {type:mime});
  }



  public visualizeBase64(imageString) {
    if(imageString) {
      return "data:image/jpg;base64,"+imageString;
    }
    
  }

  private async doLocalCrossCheck(index, docId) {



    let responseLocalCrossCheck = await this.crossCheckService.getDocumentCrossCheck(this.dataOld.sessionIdEncoded, docId)
    responseLocalCrossCheck.subscribe(
      res => {

        // Elimina lo spinner precedente
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

        this.oldDocuments[index]['localCrossCheck'] = res;  

      }, (error) => {

        // Elimina lo spinner precedente
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

        console.log("ERRORE LOCAL CROSSCHECK: ", error)
      }
    )

  }

  public createTitle(documentType) {

    console.log("Invoco createTitle: ", documentType)

    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    let englishDescription = "";

    for(const document in documentsTitles) {

      for(const part in documentsTitles[document].parts) {
        if(documentsTitles[document].parts[part].partId === documentType) {
          englishDescription = documentsTitles[document].parts[part].description;
          break;
  
        }

      }

    }

    

    for(const key in documentsTitles) {

      //console.log(englishDescription, " e poi ", documentsTitles[key].description)

      if(documentsTitles[key].description.startsWith(englishDescription)) {
        return documentsTitles[key].externalDescription;
      }

    }



  }

  public returnToward(toward) {

    if(toward == 'F') {
      return 'Fronte'
    } else {
      return 'Retro'
    }
    
  }



  goToReprocessDocument(document) {

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseRelaborate = this.documentService.relaborateDocument(this.dataOld.sessionIdEncoded,document.docId);
    responseRelaborate.subscribe(
      res => {

        // Elimina lo spinner se la risposta è andata bene
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');


          // Visualizza lo spinner
          this.element.className = 'blur'
          this.loading.classList.remove('displayLoading');

          this.pollingElaboration(document, res.sessionId, res.docId)



      }, (error) => {
        console.log("Errore in fase di rielaborazione")
      }
    )
  }


  async pollingElaboration(document, sessionId, docId) {
    let responseElaborations = await this.elaborationsService.getElaborations(sessionId, docId);

    responseElaborations.subscribe(
      res => {
        let pending = false;

        for(const key in res) {
          if(res[key].elaborationState == "PENDING") { // Se c'è un oggetto pending
            pending = true;
            break;
          }
        }

        if(pending) {
          setTimeout(()=>{                         
            this.pollingElaboration(document, sessionId, docId);
          }, 3000);
        } else {

          // Elimina lo spinner quando finisce il polling
          this.element.classList.remove('blur');
          this.loading.classList.add('displayLoading');

          document.elaborations = res;

          for(let i=0; i<this.oldDocuments.length; i++) {
            
            this.createPreviewImages(sessionId, i, document.elaborations)

          
            
          }

          // Infine, chiudi la nuova sessione creata ad hoc
          this.closeSession(sessionId);


        }

      }, (error) => {
        console.log("Errore: ", error)
      }
    );
  }

  async closeSession(sessionId) {

    let responseLoadingComplete = await this.sessionsService.setSessionStateCompleted(sessionId);

    responseLoadingComplete.subscribe(
      res => {
      }, (error) => {
        console.log("ERRORE: ", error)
      }
    )
  }


  public getExternalDescriptionByDocumentId(documentTypeId) {
    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    for(let document in documentsTitles) {
      if(documentsTitles[document].documentTypeId === documentTypeId) {
        return documentsTitles[document].externalDescription;
      }
    }

  }

  public getExternalDescriptionByPartId(partId) {

    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    for(let document in documentsTitles) {
      for(let part in documentsTitles[document].parts) {
        if(documentsTitles[document].parts[part].partId === partId) {
          return documentsTitles[document].externalDescription;
        }
      }
    }

  }




}
