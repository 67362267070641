

<div id="container">
    <app-header></app-header>

    <router-outlet></router-outlet>
</div>





<div id="loading" class="loading displayLoading">
    <div class="loadingBody">
        <mat-spinner></mat-spinner>
    </div>
</div>