<span *ngIf="data.typePopup === 'isTheRightDocument'">

  <h2 style="text-align: center;" mat-dialog-title>Attenzione!</h2>
  <mat-dialog-content class="mat-typography">
    <h3>Sei sicuro che il documento caricato corrisponda a <strong>{{data.titleDocument}}</strong> ? </h3>


  </mat-dialog-content>
  <mat-dialog-actions style="text-align: center;" align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Sì, continua elaborazione</button>
    <button mat-button [mat-dialog-close]="false">No, carica un nuovo documento</button>
    
  </mat-dialog-actions>

</span>

<span *ngIf="data.typePopup === 'OnlyPDFAllowed'">

  <h2 style="text-align: center;" mat-dialog-title>Attenzione!</h2>
  <mat-dialog-content class="mat-typography">
    <h3>È consentito solo il caricamento di file in formato <strong>PDF</strong></h3>


  </mat-dialog-content>
  <mat-dialog-actions style="text-align: center;" align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>

</span>

<span *ngIf="data.typePopup === 'ScannedPDF'">

  <h2 style="text-align: center;" mat-dialog-title>Attenzione!</h2>
  <mat-dialog-content class="mat-typography">
    <h3>È stato rilevato un documento PDF <strong>non nativo</strong> ma costituito da immagini</h3>


  </mat-dialog-content>
  <mat-dialog-actions style="text-align: center;" align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>

</span>


<span *ngIf="data.typePopup === 'ErrorFormatImagePDF'">

  <h2 style="text-align: center;" mat-dialog-title>Attenzione!</h2>
  <mat-dialog-content class="mat-typography">
    <h3>Il formato del file da te inserito non è compatibile con i tipi accettati</h3>
    <h3 style="text-align: center;">Formati accettati: <strong>JPEG</strong>, <strong>JPG</strong>, <strong>PNG</strong>, <strong>PDF</strong></h3>

  </mat-dialog-content>
  <mat-dialog-actions style="text-align: center;" align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>

</span>

<span *ngIf="data.typePopup === 'ErrorFormatImage'">

  <h2 style="text-align: center;" mat-dialog-title>Attenzione!</h2>
  <mat-dialog-content class="mat-typography">
    <h3>Il formato del file da te inserito non è compatibile con i tipi accettati</h3>
    <h3 style="text-align: center;">Formati accettati: <strong>JPEG</strong>, <strong>JPG</strong>, <strong>PNG</strong></h3>

  </mat-dialog-content>
  <mat-dialog-actions style="text-align: center;" align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>

</span>