import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SessionsService } from '../api';


export interface Session {
  sessionId: number;
  sessionIdEncoded: string;
  insertionDate: number;
  lastStateModifiedDate: number;
  state: string;
  automaticElab: object;
  callbackType: string;
  channel: string;
}

var ELEMENT_DATA: Session[] = [];

@Component({
  selector: 'app-session-check',
  templateUrl: './session-check.component.html',
  styleUrls: ['./session-check.component.scss']
})
export class SessionCheckComponent implements OnInit {

  displayedColumns: string[] = ['sessionId', 'sessionIdEncoded', 'insertionDate', 'lastStateModifiedDate', 'state', 'automaticElab', 'callbackType', 'channel'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  valueChoose

  //@ViewChild('paginator') paginator: MatPaginator;

  sessioni;

  tableSize = 5; // Numero di righe della tabella // TODO: parametrizzare

  count; // Numero TOTALE assoluto di righe

  totalPages; // Pagine totali della tabela (ovvero numero di volte che dovrò chiamare il servizio)

  currentPage = 0; // Pagina della tabella in cui sono attualmente (me la dice il servizio)

  constructor(    private router: Router,
              private sessionsService: SessionsService) { }


  ngAfterViewInit() {
    //this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    //this.dataSource.paginator = this.paginator;
  }

  navigateToOcr2(element) {
    //if(element.state == 'CLOSED' || element.state == 'COMPLETED') {
      sessionStorage.setItem("oldElaboration",JSON.stringify(element))
      this.router.navigateByUrl('/ocrResultOldSession',  { state: element });
    //}
  }

  async ngOnInit() {

    let responseGetSessions = await this.sessionsService.findSessions(null, this.currentPage,this.tableSize)

    //let responseGetSessions = await this.sessionsControllerService.getSessions(this.currentPage,this.tableSize);
    responseGetSessions.subscribe(
      response => {
        this.dataSource = <any>response['sessionsPage'];

        console.log("DATA SOURCE!!", this.dataSource)

        this.count = response['pagination']['count'];
        this.currentPage = response['pagination']['pageNumber']

        var totalPagesWithDecimals = this.count/this.tableSize;
        if(totalPagesWithDecimals % 1 == 0) {
          this.totalPages = Math.trunc(totalPagesWithDecimals) - 1;
        } else {
          this.totalPages = Math.trunc(totalPagesWithDecimals);
        }

      }, (error) => {
        console.log("ERRORE: ", error)
      }
    )

  }

  async changedSize(event) {

    this.currentPage = 0;

    let responseGetSessions = await this.sessionsService.findSessions(null, this.currentPage,this.tableSize)

    //let responseGetSessions = await this.sessionsControllerService.getSessions(this.currentPage,this.tableSize);
    responseGetSessions.subscribe(
      response => {

        this.dataSource = <any>response['sessionsPage'];
        
        this.count = response['pagination']['count'];
        
        var totalPagesWithDecimals = this.count/this.tableSize;
        if(totalPagesWithDecimals % 1 == 0) {
          this.totalPages = Math.trunc(totalPagesWithDecimals) - 1;
        } else {
          this.totalPages = Math.trunc(totalPagesWithDecimals);
        }

      }, (error) => {
        console.log("ERRORE: ", error)
      }
    )

  }

  async changedPage(event) {

    let responseGetSessions = await this.sessionsService.findSessions(null, this.currentPage,this.tableSize)

    //let responseGetSessions = await this.sessionsControllerService.getSessions(this.currentPage,this.tableSize);
    responseGetSessions.subscribe(
      response => {


        this.dataSource = <any>response['sessionsPage'];
        this.count = response['pagination']['count'];
        this.currentPage = response['pagination']['pageNumber'];

        var totalPagesWithDecimals = this.count/this.tableSize;
        if(totalPagesWithDecimals % 1 == 0) {
          this.totalPages = Math.trunc(totalPagesWithDecimals) - 1;
        } else {
          this.totalPages = Math.trunc(totalPagesWithDecimals);
        }



      }, (error) => {
        console.log("ERRORE: ", error)
      }
    )

  }





  // Chiamato per andare verso una sezione successiva
  async nextSessions() {

    if(this.currentPage < this.totalPages) {
      
      let responseGetSessions = await this.sessionsService.findSessions(null, this.currentPage + 1,this.tableSize)
      //let responseGetSessions = await this.sessionsControllerService.getSessions(this.currentPage + 1,this.tableSize);
      responseGetSessions.subscribe(
        response => {
          this.dataSource = <any>response['sessionsPage'];
          this.currentPage = response['pagination']['pageNumber']
        }, (error) => {
        }
      )
    }

  }

  // Chiamato per andare verso una sezione precedente
  async previousSessions() {

    if(this.currentPage >= 1) {
      //let responseGetSessions = await this.sessionsControllerService.getSessions(this.currentPage - 1,this.tableSize);
      
      let responseGetSessions = await this.sessionsService.findSessions(null, this.currentPage - 1,this.tableSize)
      responseGetSessions.subscribe(
        response => {
          this.dataSource = <any>response['sessionsPage'];
          this.currentPage = response['pagination']['pageNumber'];
        }, (error) => {
        }
      )
    }

  }


  searchedSession;

  errorSearch = false;

  async searchSessionClicked() {

    this.errorSearch = false;

    let responseGetSessions = await this.sessionsService.findSessions(this.searchedSession,null,null )
    responseGetSessions.subscribe(
      res => {
        this.dataSource = <any>res['sessionsPage'];
      },(error) => {
        this.errorSearch = true;
        console.log("Errore ricerca sessione: ", error)
      }
    )

  }

  backToBlack() {
    this.errorSearch = false;
  }


  

}
