import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import { ConfigurationsService, DocumentsService, ElaborationsService, ServicesService } from '../api';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';

import { DocumentModel } from '../api/model/documentModel';
import { MatDialog } from '@angular/material/dialog';
import * as pdfjs from 'pdfjs-dist/build/pdf.js';


@Component({
  selector: 'popup-component',
  templateUrl: 'popup-component.html',
})
export class PopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
 ) { }
 
 ngOnInit() {

 }
}


@Component({
  selector: 'app-document-extraction',
  templateUrl: './document-extraction.component.html',
  styleUrls: ['./document-extraction.component.scss']
})
export class DocumentExtractionComponent implements OnInit, OnChanges {

  appear = true;
  @Input() firstCard;

  @Input('deleteAll') deleteAll: boolean

  @Input('left') public left: number;
  @Input('top') public top: number; 

  @Output() eventFinishElaborationsFronte = new EventEmitter(); // Evento che scatta quando l'elaborazione di una foto FRONTE è finita
  @Output() eventFinishElaborationsRetro = new EventEmitter(); // Evento che scatta quando l'elaborazione di una foto FRONTE è finita


  @Output() eventDeletedElaborationsFronte = new EventEmitter();
  @Output() eventDeletedElaborationsRetro = new EventEmitter();

  objectElaborationsFronte = {} // Oggetto che raccoglie tutti i dati fondamentali di una elaborazione 
  objectElaborationsRetro = {} // Oggetto che raccoglie tutti i dati fondamentali di una elaborazione 

  // Servono per lo spinner
  element;
  loading;

  public image: string = "assets/images/noImage.jpg";


  public urlFronte: any; // Serve per visualizzare nel template l'immagine
  public urlRetro: any; // Serve per visualizzare nel template l'immagine

  public imageFileFronte : File; // Serve per avere metadati sull'immagine (nome e size) SERVE PER IL SERVIZIO
  public imageFileRetro : File; // Serve per avere metadati sull'immagine (nome e size)

  public modeProgressBarFronte: string = 'determinate'; // Indica lo stato della progress bar
  public valueProgressBarFronte: number = 0; // Indica la percentuale della progress bar
  public modeProgressBarRetro: string = 'determinate'; // Indica lo stato della progress bar
  public valueProgressBarRetro: number = 0; // Indica la percentuale della progress bar


  public objectElaborations = {};
  public urls = {};
  public imageFiles = {};
  public modeProgressBars = {};
  public valueProgressBars = {}
  @Output() eventFinishElaborations = new EventEmitter();
  @Output() eventDeletedElaborations = new EventEmitter();


  public documenti = []; // Array in cui ogni oggetto è un particolare documento

  @Input() documentsExternal; // Array di documenti "rimasti" 

  public selectedDocument; // Documento selezionato

  public submissionTypes = []; // Array che indica i possibili modi in cui può essere acquisito il documento

  private allowedImagesFormats = ['image/jpeg', 'image/jpg', 'image/png']; // Formati consentiti per quanto riguarda le immagini

  private allowedIncomeFormats = ['application/pdf']; // Formati consentiti per quanto riguarda i PDF

  constructor(
    private configurationsService: ConfigurationsService,
    private servicesService: ServicesService,
    private documentsService: DocumentsService,
    private elaborationsService: ElaborationsService,

    public dialog: MatDialog
  ) {
    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');
  }

  async ngOnChanges(){

    if(this.documentsExternal) {
      this.documenti = this.documentsExternal;
    }

  }

  async ngOnInit() {

    let responseDocumentTypes = await this.configurationsService.getDocumentTypes();
    responseDocumentTypes.subscribe(
      res => {

        console.log("La resss: ", res[0]);

        let filteredDocuments = this.getSubsetOfGlobalDocuments(res);

        sessionStorage.setItem('documents', JSON.stringify(filteredDocuments));

        if(this.documentsExternal) {
          this.documenti = this.documentsExternal;
        } else {

          for(const key in filteredDocuments) {
            this.documenti.push(filteredDocuments[key]);
          }
        }
        
      }, (error) => {
        console.log("ERRORE")
      }
    );
  }

  // Restituisce true se tutte le parts del documento attuale hanno 'active' a false; false altrimenti
  public allPartsAreNotVisible(doc) {

      for(let part of doc.parts) {
        if(part.active === true) {
          return false;
        }
      }
  
      return true;
  }

  // Restituisce un sottoinsieme di tipi di documenti filtrato per un array contenente solo il nome dei tipi
  private getSubsetOfGlobalDocuments(allDocumentsType) {

    console.log("allDocumentsType[0]: ", allDocumentsType[0]);

    let subset = []; // Qui dentro vengono salvati i tipi di documenti originariamente dentro ad 'allDocumentsType'

    for(let documentType of allDocumentsType) {
      if(!this.allPartsAreNotVisible(documentType)) {
        subset.push(documentType);
      }
    }

    return subset;

  }


  // Se seleziono dalla tendina un documento diverso, devo ripristinare lo stato iniziale, annullando tutto ciò che è stato fatto
  public documentHasChanged(value): void {

    this.submissionTypes = [];
    for(let type of this.selectedDocument.submissionTypes) {
      this.submissionTypes.push(type.submissionTypeId)
    }
    this.submissionTypes = [...new Set(this.submissionTypes)]; // Elimina tutte le stringhe duplicate

    // Resetta tutti i metadati
    this.objectElaborations = {};
    this.urls = {};
    this.imageFiles = {};
    this.modeProgressBars = {};
    this.valueProgressBars = {}

      // Cicla per ogni 'part' del documento attualmente selezionato, annullando tutti i metadati relativi alla singola 'part'
    if(value && value.parts) {
      for(const part of value.parts) {

        if(part.active === true) {

          this.objectElaborations[part.name] = {};
          this.urls[part.name] = null;
          this.imageFiles[part.name] = null;
          this.modeProgressBars[part.name] = 0;
          this.valueProgressBars[part.name] = 0;
        }

      }
    }

  }


  // Funzione che data una size in byte la converte nell'unità più "friendly"
  public formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';
 
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openDialog(titleDocument, toward, labels, part) {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {
        typePopup : 'isTheRightDocument',
        titleDocument: titleDocument
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result) { // Se sì, devo continuare l'elaborazione

        this.uploadDocumentPart(this.selectedDocument.documentTypeId, part.name, this.imageFiles[part.name], labels, part)

      } else { // Altrimenti, chiudo tutto

        this.backToInitialState(toward, false);

      }

    });
  }


  // **************************Nuovo sviluppo***********************


  /* Ordina l'array di parts utilizzando le externalDescription in ordine alfabetico */
  public partInLexicalOrder(parts) {
    return parts.sort( this.compare ); 
  }

  /* Funzione di utilità: Ordina l'array di parts utilizzando le externalDescription in ordine alfabetico */
  private compare( a, b ) {
    if ( a.externalDescription < b.externalDescription ){
      return -1;
    }
    if ( a.externalDescription > b.externalDescription ){
      return 1;
    }
    return 0;
  }

  // Start loading photo
  public async startLoadingPhoto(part) {

    this.valueProgressBars[part.name] = 50; // Imposta la barra per arrivare a metà

    let responseCheckDocumentType = await this.servicesService.checkDocumentTypeForm(this.imageFiles[part.name]);

    responseCheckDocumentType.subscribe(

      res => {    
        // Va fatta la funzione intelligente che cerca documentTypeList (è in posizione o 0 oppure 1)

        let labelPredicted = [];

        for(const i in res) {
          if ('labelPredicted' in res[i]) {
            labelPredicted.push(res[i].labelPredicted);
            //break;
          }
        }

        // Controlla se è presente almeno un documentTypeList
        let foundDocumentTypeList = false;
        for(const i in res) {
          if ('documentTypeList' in res[i]) {
            foundDocumentTypeList = true;
            break;
          }
        }

        if(!foundDocumentTypeList) {
          this.openDialog(this.selectedDocument.externalDescription + " - " + part.externalDescription, part.name, JSON.stringify(labelPredicted), part);
        } else {
          for(const i in res) {
            if ('documentTypeList' in res[i]) {
              if(res[i].documentTypeList.length > 0) {
  
                if(res[i].documentTypeList[0].documentType == this.selectedDocument.documentTypeId && res[i].documentTypeList[0].documentToward === part.name) {
  
                  this.objectElaborationsFronte["labels"] = labelPredicted; // Salva le labels nell'oggetto finale da mandare al patent
  
                  this.uploadDocumentPart(this.selectedDocument.documentTypeId, part.name, this.imageFiles[part.name], JSON.stringify(labelPredicted), part) // TODO: res[i].labelPredicted non corretto
                  
                } else {
                  this.openDialog(this.selectedDocument.externalDescription + " - " + part.externalDescription, part.name, JSON.stringify(labelPredicted), part);
                }
  
              } else {
                this.openDialog(this.selectedDocument.externalDescription + " - " + part.externalDescription, part.name, JSON.stringify(labelPredicted), part);
              }
            }
          }
        }

      }, (error) => {
        console.log("ERRORE CARICAMENTO ", error)
      }
    )

  }


  
  // Upload per ottenere un docId
  public async uploadDocumentPart(documentType: string, frontBack: string, photo: File, labels: any, part: any) {

    
    let documentModel: DocumentModel = { 
      upfile: photo,
      documentToward: frontBack,
      documentType: documentType
    }

    let responseDocuments = this.documentsService.createDocumentForm( // Invocazione alla POST Document
      sessionStorage.getItem('sessionId'),
      documentModel,
      labels
    )
    responseDocuments.subscribe(
      res => {
        this.valueProgressBars[part.name] = 100;
        this.pollingElaborations(res["docId"], part);
      }, (error) => {
        console.log("ERRORE NEL CARICAMENTO DOCUMENTO", error);
      }
    )
  }

  // Polling Elaborations
  public async pollingElaborations(docId, part) {

    this.modeProgressBars[part.name] = 'indeterminate';

    let responseElaborations = await this.elaborationsService.getElaborations(sessionStorage.getItem('sessionId'),docId);

    responseElaborations.subscribe(
      res => {        

        console.log("TEST getElaborations: ", res)

        let pending = false;

        for(const key in res) {
          if(res[key].elaborationState == "PENDING") { // Se c'è un oggetto pending
            pending = true;
            break;
          }
        }

        if(pending) {

          setTimeout(()=>{                         
            this.pollingElaborations(docId, part);
          }, 3000);

        } else {
          this.modeProgressBars[part.name] = 'determinate';

          // Crea l'array da restituire con l'evento, senza però il documento appena usato
          let documentsArrayCopy = JSON.parse(JSON.stringify(this.documenti));
          let documentsArrayCopyWithoutActualDoc = documentsArrayCopy.filter(item => item.documentTypeId != this.selectedDocument.documentTypeId);

          this.objectElaborations[part.name]["docId"] = docId;
          this.objectElaborations[part.name]["photo"] = this.imageFiles[part.name];
          this.objectElaborations[part.name]["elaborations"] = res;
          this.objectElaborations[part.name]["title"] = this.selectedDocument.documentTypeId;
          this.objectElaborations[part.name]["toward"] = part.externalDescription;
          this.objectElaborations[part.name]["parts"] = this.selectedDocument.parts;
          this.objectElaborations[part.name]["part"] = part;
          this.objectElaborations[part.name]["documentsExternal"] = documentsArrayCopyWithoutActualDoc;


          let eventFinish = {
            "object": this.objectElaborations[part.name]
          }
          
          this.eventFinishElaborations.emit(eventFinish);
        }
        

      }, (error) => {
        console.log("ERRORE DURANTE POLLING", error);
      }
    );

  }

  public removeUploadedPhoto(part_name): void {
    this.backToInitialState(part_name, false);
  }

  @Output() eventNoMoreAppear = new EventEmitter();

  private backToInitialState(part_name, isDelete): void {
    this.modeProgressBars[part_name] = 'determinate';
    this.valueProgressBars[part_name] = 0;
    this.urls[part_name] = undefined;
    this.imageFiles[part_name] = undefined;

    let nullable = {}

    let eventFinish = {};

    // Se tutte le parts sono state rimosse -> Ri-assiungi il documento
    if(isDelete) {
      if(this.allPartsAreNull()) {
        this.appear = false;
        this.eventNoMoreAppear.emit(true);
        nullable = this.selectedDocument
      } else {
        this.appear = true;
      }


      eventFinish = {
        "object": this.objectElaborations[part_name],
        "nullable" : nullable
      }
    }

    this.objectElaborations[part_name] = {};

    if(isDelete) {
      this.eventDeletedElaborations.emit(eventFinish) // TODO: Aggiungere qualcosa a questo evento
    }

  }

  

  public allPartsAreNull() {

    for(let url of Object.keys(this.urls)) {
      if(this.urls[url]) {
        return false;
      }
    }

    return true;

  }

  public async deleteUploadedPhoto(part) {

    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');

    if(this.objectElaborations[part.name]["docId"]) {
    // Visualizza lo spinner
      this.element.className = 'blur'
      if(this.loading && this.loading.classList) {
        this.loading.classList.remove('displayLoading');
      }
      
    
      // Invocazione al servizio di delete
      let responseDelete = await this.documentsService.cancelDocument(sessionStorage.getItem('sessionId'), this.objectElaborations[part.name]["docId"]);
      responseDelete.subscribe(
        res => {
          console.log("TEST CANCELLAZIONE: ", res)
          // Elimina lo spinner se la risposta è andata bene
          this.element.classList.remove('blur');
          if(this.loading && this.loading.classList) {
            this.loading.classList.add('displayLoading');
          }
          this.backToInitialState(part.name, true);
        }, (error) => {
          // Elimina lo spinner anche se la risposta è andata male
          this.element.classList.remove('blur');
          if(this.loading && this.loading.classList) {
            this.loading.classList.add('displayLoading');
          }
          console.log("ERRORE CANCELLAZIONE: ", error)
        }
      );

    }


  }
  

  isOneBarIndeterminate() {

    for(let key in this.modeProgressBars) {
      if(this.modeProgressBars[key] === 'indeterminate') {
        return true;
      }
    }
    return false;

  }

  isOneProgressBarEqualTo100() {

    for(let key in this.valueProgressBars) {
      if(this.valueProgressBars[key] === 100) {
        return true;
      }
    }
    return false;

  }

  // UploadDocument
  public uploadDocumentImage(event: any, part: any): void {
    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    
    var mimeType = event.target.files[0].type;

    
    if(this.allowedImagesFormats.includes(mimeType)) {
      this.imageFiles[part.name] = event.target.files[0];
      
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      
      reader.onload = (_event) => {
        this.urls[part.name] = reader.result; 
      }
    } else {

      console.log("ci entro?")

      const dialogRef = this.dialog.open(PopupComponent, {
        data: {
          typePopup : 'ErrorFormatImage',
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
        if(result) { // Se sì, devo continuare l'elaborazione    
        }
  
      });

    }

  }

  public uploadDocumentNativePDF(event: any, part: any): void {

   //this.pdfIsLoading = true;
    this.loading = true; // Il "caricamento apparente" di un file PDF può essere più lungo
    //this.sessionService.blockSession = true;

    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type;

    if (this.allowedIncomeFormats.includes(mimeType)) {
      this.isScannedOrNot(event, part);
    } else { // Appare un popup di errore
      const dialogRef = this.dialog.open(PopupComponent, {
        data: {
          typePopup : 'OnlyPDFAllowed',
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
        if(result) { // Se sì, devo continuare l'elaborazione
        }
  
      });
    }

  }


  public async isScannedOrNot(event: any, part: any) {

    if(!event.target.files[0] || event.target.files[0].length == 0) {
        return;
    }
        
    let file = event.target.files[0];

    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    var selfRef = this; // Serve per utilizzare il this dentro la closure

    fileReader.onload = async function() {

      const doc = await pdfjs.getDocument(this.result);

      const numPages = await doc.numPages;

      let total_text_area = 0;
      let total_pdf_area = 0;

      console.log("total_text_area:", total_text_area)

      for(let i=1; i <= numPages; i++) {

        const page = await doc.getPage(i);
        const textContent = await page.getTextContent();

        let text_area = 0;
        let page_area = page.view[2] * page.view[3]; // L'array page.view ha semantica [left, top, width, height]

        for(let obj of textContent.items) {
          text_area += obj.width * obj.height;
        }

        total_text_area += text_area;
        total_pdf_area += page_area;

      }

      if(total_text_area / total_pdf_area >= 0.08) { // 0.1 è per adesso la soglia scelta di tolleranza alla quantità di testo scritto dentro il documento .pdf
        console.log("Probabilmente nativo");
        // Serve per visualizzare il PDF dentro la card
        
        selfRef.imageFiles[part.name] = event.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          selfRef.urls[part.name] = reader.result; 

          selfRef.urls[part.name] = selfRef.urls[part.name]; 

          //selfRef.pdfIsLoading = false;
          selfRef.loading = false; // Il "caricamento apparente" di un file PDF può essere più lungo
          //selfRef.sessionService.blockSession = false;

        }
        
      } else {

        console.log("È stato rilevato un documento PDF non nativo ma costituito da immagini")

        const dialogRef = selfRef.dialog.open(PopupComponent, {
          data: {
            typePopup : 'ScannedPDF',
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
    
          if(result) { // Se sì, devo continuare l'elaborazione    
          }
    
        });
      }
      
    }

  }



  public uploadDocumentImagePdf(event: any, part: any): void {

    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type; // mimeType è il tipo del documento (formato)
    
    if(this.allowedImagesFormats.includes(mimeType) || this.allowedIncomeFormats.includes(mimeType)) {

      if(this.allowedImagesFormats.includes(mimeType)) { // SE E' UNA IMMAGINE
         // Se la dimensione dell'immagine è entro quella massima consentita : packageInfo.maxImgSize
        this.imageFiles[part.name] = event.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.urls[part.name] = reader.result; 
        }
        
      } else { // Se è un PDF

        this.imageFiles[part.name] = event.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.urls[part.name] = reader.result; 

          this.urls[part.name] = this.urls[part.name];
        }
      }

    } else { // Appare un popup di errore      
      
      const dialogRef = this.dialog.open(PopupComponent, {
        data: {
          typePopup : 'ErrorFormatImagePDF',
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
        if(result) { // Se sì, devo continuare l'elaborazione    
        }
  
      });
    }
    
  }

}
