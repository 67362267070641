import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ConfigurationsService } from './api/configurations.service';
import { CrossCheckService } from './api/crossCheck.service';
import { DocumentsService } from './api/documents.service';
import { ElaborationsService } from './api/elaborations.service';
import { LogsService } from './api/logs.service';
import { ServicesService } from './api/services.service';
import { SessionsService } from './api/sessions.service';
import { StatisticsService } from './api/statistics.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ConfigurationsService,
    CrossCheckService,
    DocumentsService,
    ElaborationsService,
    LogsService,
    ServicesService,
    SessionsService,
    StatisticsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
