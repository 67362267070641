import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk';

AWS.config.update({
  region: 'us-east-2', // TODO: sostituire con stringa nel config  
});


@Injectable({
  providedIn: 'root'
})
export class LoginService {



  correctLogin() {
    if(sessionStorage.getItem("correctLogin") == "true") {
      return true;
    } else {
      return false;
    }
  }

  constructor() { }




}
