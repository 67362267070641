<div class="card">
    
    <!-- Header della card: di base ha sfondo grigio ma può variare con l'icona dell'omino mentre c'è il caricamento, oppure con OK -->
    <div class="card-header">
        <span class="bold">Patente</span> - Fronte
        <div>
            <!--
            <div class="card-status-icon">
                <div class="cog" style="height: 40px; width: 40px; display: none;">
                    <img class="reply-gif" [src]="image" style="height: 100%;">
                </div>
            </div>
            -->

            <!--
            <div class="card-status-icon warn-tooltip" data-toggle="tooltip" data-placement="top" title="" data-original-title="Oopps! I risultati non sono buoni. Controlla la qualità della foto!">
                <div class="warning fa fa-exclamation-triangle" style="height: 40px; width: 40px; font-size: 40px; color: darkorange;">
                </div>
            </div>
            -->

            <div class="card-status-icon">
                    <img alt="Successo" *ngIf="success" class="imgStatus" [src]="imageSuccess" style="height: 100%;">
            </div>

            <!--
            <div class="card-status-icon">
                <div class="error" style="height: 40px; width: 40px">
                    <img [src]="imageError" style="height: 100%;">
                </div>
            </div>
            -->
        </div>
    </div>

    
    <div class="card-body" data-document="DRIVER_LICENSE" data-direction="F">
        <div class="form-group"><div class="row" data-document="DRIVER_LICENSE" data-direction="F">
            <span class="left col col-xl-7 col-12 mt-2" data-direction="F" data-document="DRIVER_LICENSE"></span>
            <div class="col col-xl-5 col-12 mt-2"><div class="right row" data-direction="F" data-document="DRIVER_LICENSE">
                
                <span class="input-group-btn col-md-6 col-6 offset-md-6 offset-6">
                    <!--
                    <button class="btn btn-primary btn-choose add-btn w-100" type="button" data-direction="F" data-document="DRIVER_LICENSE">
                        <i class="fa fa-plus" aria-hidden="true"></i> Foto
                    </button>
                    -->

                    <div *ngIf="!url">
                        <label for="file-upload" class="action-button file-upload">
                            <div class="textInsideButton"><em class="fa fa-plus"></em> Foto</div>
                        </label>
                        <input id="file-upload" (change)="selectFile($event)" type="file"/>
                    </div>

                    <div *ngIf="url">
                        <button (click)="startLoadingPhoto()" [disabled]="modeProgressBar == 'indeterminate'? true : false" class="action-button file-start-upload" *ngIf="valueProgressBar != 100">
                            <em class="fa fa-upload"></em> Carica
                        </button>

                        <button (click)="removeUploadedPhoto()" [disabled]="modeProgressBar == 'indeterminate'? true : false" class="action-button file-remove-upload" *ngIf="valueProgressBar != 100">
                            <em class="fa fa-ban"></em> Annulla
                        </button>

                        <button (click)="deleteUploadedPhoto()" class="action-button file-delete-upload" *ngIf="valueProgressBar == 100">
                            <em class="fa fa-trash"></em> Cancella
                        </button>


                        <!--Informazioni su nome file e pesantezza file-->
                        {{imageFile.name}} {{formatBytes(imageFile.size)}}
                        <mat-progress-bar [mode]="modeProgressBar" [value]="valueProgressBar"></mat-progress-bar>

                        <!--
                            Mentre avanza la barra dinamicamente, i pulsanti file-start-upload e file-delete-upload sono disabled
                            Se la barra è al 100%, i pulsanti scompaiono e appare il pulsante della cancellazione -->
                    </div>

                    <div class="divUploadedImage">
                        <img alt="Immagine caricata" class="uploadedImage" [src]="url" *ngIf="url">
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="img-keeper" data-direction="F" data-document="DRIVER_LICENSE">
    <img alt="Immagine documento" class="document-image" data-document="DRIVER_LICENSE" data-direction="F"  *ngIf="!url" [src]="image">
</div>