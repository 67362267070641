
    <div class="row ml-1 blueNavBar">


        <a class="titleSection" routerLink="/home">Elaborazione documenti</a>

        <a class="titleSection2" routerLink="/sessionCheck">Storico sessioni</a>

        <a class="titleSection2" routerLink="/log">Log</a>

        <a class="titleSection2" routerLink="/statistics">Statistiche</a>

        <a class="titleSection2" routerLink="/settings">Impostazioni</a>

        <div class="ml-auto mr-3">
          <!--<span [ngClass]="{'hide': isDisabled(), 'visible' : !isDisabled() }" class="autenticatedUserString">Accesso come {{userLogged}}</span>-->
          <button class="action-button" [disabled]="isDisabled()" (click)="logout()">Logout</button>
        </div>
    

    </div>
