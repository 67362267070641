import { Component, OnInit } from '@angular/core';
import { CrossCheckService } from '../api';

@Component({
  selector: 'app-global-cross-check',
  templateUrl: './global-cross-check.component.html',
  styleUrls: ['./global-cross-check.component.scss']
})
export class GlobalCrossCheckComponent implements OnInit {

  // Servono per lo spinner
  element;
  loading;

  docsCrossCheckList;
  globalCrossCheck;

  isDataLoaded = false;


  titles = {};

  constructor(
    private crossCheckService: CrossCheckService
  ) {
    this.element = document.getElementById('container');
    this.loading = document.getElementById('loading');
  }

  ngOnInit(): void {
    this.doGlobalCrossCheck();
    //this.createTitle();
  }

  private async doGlobalCrossCheck() {

    // Visualizza lo spinner
    this.element.className = 'blur'
    this.loading.classList.remove('displayLoading');

    let responseGlobalCrossCheck = await this.crossCheckService.getGlobalCrossCheck(sessionStorage.getItem('sessionId'));
    responseGlobalCrossCheck.subscribe(
      res => {

        // Elimina lo spinner se la risposta è andata bene
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');

        this.docsCrossCheckList = res["docsCrossCheck"]; // TODO: è giusto prendere il primo elemento sempre? Perchè è un arra
        this.globalCrossCheck = res["globalCrossCheck"];

        this.isDataLoaded = true;
        this.createTitle();
      }, (error) => {
        this.element.classList.remove('blur');
        this.loading.classList.add('displayLoading');
        console.log("ERRORE GLOBAL CROSSCHECK: ", error)
      }
    );
    
  }

  private createTitle() {
    var documentsTitles = JSON.parse(sessionStorage.getItem('documents'));

    for(const docCrossCheck in this.docsCrossCheckList) {

      for(const key in documentsTitles) {
        if(documentsTitles[key].documentTypeId == this.docsCrossCheckList[docCrossCheck].docType) {
          this.titles[docCrossCheck] = documentsTitles[key].externalDescription;
        }
      }
    }

  }

}
