<h1>Storico sessioni</h1>


<mat-toolbar class="blueNavBar">
    <mat-toolbar-row>

        
        <form class="form-inline md-form form-sm active-cyan-2">
            <input [ngClass]="{'red': errorSearch == true }" (ngModelChange)="backToBlack()" class="searchBar form-control form-control-sm mr-3 w-99" type="text" name="searchSession" [(ngModel)]="searchedSession" placeholder="Inserisci ID Sessione codificato" aria-label="Search" mdbInput>
            <em (click)="searchSessionClicked()" class="fa fa-search"></em>
        </form>

      <span class="example-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>

<br><br>






<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="Sessioni">

    <ng-container matColumnDef="sessionId">
      <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> ID Sessione </th>
      <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.sessionId}} </td>
    </ng-container>

    <ng-container matColumnDef="sessionIdEncoded">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> ID Sessione codificato </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.sessionIdEncoded}} </td>
    </ng-container>

    <ng-container matColumnDef="insertionDate">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> Data di inserimento </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.insertionDate | date:'MMMM d y, h:mm:ss a'}} </td>
    </ng-container>

    <ng-container matColumnDef="lastStateModifiedDate">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> Data ultima modifica </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.lastStateModifiedDate | date:'MMMM d y, h:mm:ss a'}} </td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> Stato sessione </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.state}} </td>
    </ng-container>

    <ng-container matColumnDef="automaticElab">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> Elaborazione automatica </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.automaticElab}} </td>
    </ng-container>

    <ng-container matColumnDef="callbackType">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> Tipo callback </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.callbackType}} </td>
    </ng-container>

    <ng-container matColumnDef="channel">
        <th scope="col" class="headerColumn" mat-header-cell *matHeaderCellDef> Canale </th>
        <td mat-cell *matCellDef="let element" (click)="navigateToOcr2(element)" class="pointer"> {{element.channel}} </td>
    </ng-container>

   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <!--<mat-paginator #paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>-->

  <!--

  <br><br><br>
  
  <div class="information"><b>ACCEDI ALLE INFORMAZIONI DI UNA<br> SESSIONE OPPURE ESEGUI UN NUOVO TEST</b></div>

  <br><br><br>

  <div class="information"><button class="action-button reprocess-documento"><b>ESEGUI TEST</b></button></div>

  -->








<div class="row center">

    


    <div class="col-md-4 example-button-row">

        <mat-form-field appearance="fill">
            <mat-label>Dimensione tabella</mat-label>
            <mat-select [(ngModel)]="tableSize" (ngModelChange)="changedSize($event)">
              <mat-option value="5">5</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="15">15</mat-option>
              <mat-option value="20">20</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div class="col-md-4 example-button-row">

        <button mat-raised-button color="primary" [disabled]="currentPage <= 0" (click)="previousSessions()">Indietro</button>
        <button mat-raised-button color="primary" [disabled]="currentPage >= totalPages" (click)="nextSessions()">Avanti</button>

    </div>

    <div class="col-md-4 example-button-row">

        <mat-form-field appearance="fill">
            <mat-label>Vai a pagina</mat-label>
            <mat-select [(ngModel)]="currentPage" (ngModelChange)="changedPage($event)">
                <mat-option [value]="0">0</mat-option>
                <div *ngFor="let item of [].constructor(totalPages); let i = index">
                    
                    <mat-option [value]="i+1">{{i+1}}</mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
        
    
</div>

<div class="row center">
    <div class="col-md-12">
        <div class="navigationIndicator"><b>{{currentPage}} di {{totalPages}}</b></div>
    </div>
</div>

